import { Box, useMediaQuery } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import React from 'react';
import useStyles from './styles';
import { useTheme } from '@mui/material/styles';

interface Props {
  MasterComponent: React.ComponentType;
  DetailComponent: React.ComponentType;
  detailRelativePath: string;
}

/**
 * Master-Detail Layout Component
 *
 * This component renders a master-detail layout for applications with a two-pane structure.
 * It utilizes Material UI components and React Router for routing and styling.
 *
 * Props:
 *  - `MasterComponent` (React.ComponentType): The React component to be rendered in the master pane.
 *  - `DetailComponent` (React.ComponentType): The React component to be rendered in the detail pane.
 *  - `detailRelativePath` (string): The relative path for the detail component in the URL (e.g., '/detail').
 *
 * Functionality:
 *  - Uses `useMediaQuery` from Material UI to check for screen size (large or small).
 *  - Renders the layout based on screen size:
 *      - Large Screen (Desktop):
 *          - Two-pane layout with `MasterComponent` in the left pane and `DetailComponent` in the right pane.
 *          - `DetailComponent` is rendered for both the root path (`/`) and the `detailRelativePath`.
 *      - Small Screen (Mobile):
 *          - Single-pane layout where routes determine which component is displayed:
 *              - Root path (`/`) renders the `MasterComponent`.
 *              - `detailRelativePath` renders the `DetailComponent`.
 *  - Uses `useStyles` and `useTheme` from Material UI for styling based on theme breakpoints.
 *
 * @returns {JSX.Element} - The rendered master-detail layout component.
 */
const MasterDetailLayout: React.FC<Props> = (props: Props) => {

  const { MasterComponent, DetailComponent, detailRelativePath } = props;
  const styles = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    isLargeScreen ?
      (
        <Box sx={styles.masterLayout}>
          <Box sx={styles.leftPane}>
            <MasterComponent />
          </Box>
          <Box sx={styles.rightPane}>
            <Routes>
              <Route path='/' element={<DetailComponent />} />
              <Route path={detailRelativePath} element={<DetailComponent />} />
            </Routes>
          </Box>
        </Box>
      ) : (
        <Routes>
          <Route path={'/'} element={<MasterComponent />} />
          <Route path={detailRelativePath} element={<DetailComponent />} />
        </Routes>
      )
  );
};

export default MasterDetailLayout;
