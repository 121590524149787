/**
 * Application navigation constants.
 *
 * This file defines constant values for various application routes.
 * These constants are used throughout the application to navigate users
 * to different screens or pages.
 *
 * It's recommended to use these constants instead of hardcoding route strings
 * for better maintainability and easier updates to application routes.
 */
const APP_NAV = {
  SIGN_IN: '/sign-in',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY: '/verify',
  ORGANIZATION: '/organization',
  ORG_DETAIL_REL_PATH: '/detail'
} 

export default APP_NAV;