import DarkLogo from '../../assets/logo_dark.svg';
import Dimens from '../../theme/dimens';
import LightLogo from '../../assets/logo_light.svg';
import ThemeUtil from '../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the LogoHeader component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    header: {
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      pb: Dimens.spacing.card,
      'h1': {
        pb: Dimens.spacing.lg,
        fontWeight: Dimens.fontWeight.bold
      }
    },
    logo: {
      height: Dimens.icon.xl,
      content: `url(${ThemeUtil.isLightTheme(theme) ? LightLogo : DarkLogo})`
    }
  };

  return styles;
};

export default useStyles;
