import { Box } from '@mui/material';
import React from 'react';
import useStyles from './styles';

const BackUpTab: React.FC = () => {

  const styles = useStyles();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.innerWrapper}>
        {'Features'}
      </Box>
    </Box>
  );
};

export default BackUpTab;

