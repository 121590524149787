import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../CustomButton';
import Dimens from '../../theme/dimens';
import React from 'react';
import useStyles from './styles';

interface Props extends DialogProps {
	title: string;
	titleIcon: React.ReactNode | string;
	message: string;
	/**
	 * The size of the dialog.
	 * @default false
	 */
	isDestructive?: boolean;
	/**
	 * The size of the dialog.
	 * @default false
	 */
	isLarge?: boolean;
	primaryLabel?: string;
	secondaryLabel?: string;
	onPrimaryAction?: () => void;
	onSecondaryAction?: () => void;
	onClose: () => void;
}

/**
 * Reusable modal dialog component for displaying alerts and confirmations.
 *
 * This component provides a flexible dialog with a title, optional icon, message,
 * and action buttons. It supports different sizes (small, large), destructive actions,
 * and customizable content.
 *
 * @props {Props} - Component properties.
 *   - `title` (string, required): The title text displayed at the top of the dialog.
 *   - `titleIcon` (React.ReactNode | string, optional): Title icon element or image source URL.
 *   - `message` (string, required): The message content displayed within the dialog body.
 *   - `isDestructive` (bool, optional): Flag to style the primary button for destructive actions (default: false).
 *   - `isLarge` (bool, optional): Flag to render a larger dialog with more space (default: false).
 *   - `primaryLabel` (string, optional): Label text for the primary action button.
 *   - `secondaryLabel` (string, optional): Label text for the secondary action button.
 *   - `onPrimaryAction` (func, optional): Function to call when the primary button is clicked.
 *   - `onSecondaryAction` (func, optional): Function to call when the secondary button is clicked.
 *   - `onClose` (func, required): Function to call when the dialog is closed (e.g., via close button or backdrop click).
 *   - Rest of the props are spread to the underlying `Dialog` component from Material UI.
 *
 * @returns {JSX.Element} - The rendered alert dialog component.
 */
const AlertDialog: React.FC<Props> = (props: Props) => {

	const {
		title,
		titleIcon,
		message,
		isDestructive,
		isLarge,
		primaryLabel,
		secondaryLabel,
		onPrimaryAction,
		onSecondaryAction,
		onClose,
		...rest
	} = props;
	const styles = useStyles();

	return (
		<Dialog {...rest} sx={[styles.dialog, isLarge ? styles.dialogLarge : styles.dialogSmall]}>
			<DialogTitle>
				<Stack sx={{ ...styles.header, ...(isLarge && styles.noPad) }}>
					{!isLarge && titleIcon}
					<Box flex={1} />
					<IconButton onClick={onClose} >
						<CloseIcon sx={styles.closeIcon} />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				{isLarge &&
					<Box
						component='img'
						alt='logo'
						src={titleIcon as string}
						sx={styles.headerImage}
					/>}
				<Typography
					variant={isLarge ? 'h3' : 'h5'}
					fontWeight={Dimens.fontWeight.bold}
				>
					{title}
				</Typography>
				<Typography
					variant='p1'
					sx={isLarge ? styles.messageLarge : styles.messageSmall}
				>
					{message}
				</Typography>
			</DialogContent>
			<DialogActions sx={isLarge ? styles.actionLarge : styles.actionSmall}>
				{secondaryLabel &&
					<CustomButton
						title={secondaryLabel}
						color='secondary'
						onClick={onSecondaryAction}
					/>
				}
				{primaryLabel &&
					<CustomButton
						title={primaryLabel}
						color='primary'
						destructive={isDestructive}
						onClick={onPrimaryAction}
					/>
				}
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
