import { AdminStatus, OrgStatus } from '../../../../../utils/constants';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import APIConfig from '../../../../../service/api-config';
import CrossIcon from '../../../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../../../components/CustomButton';
import CustomInput from '../../../../../components/CustomInput';
import { INVITE_ADMIN } from '../../../../../data/organization/action-types';
import InlineAlert from '../../../../../components/InlineAlert';
import Organization from '../../../../../types/organization';
import OrganizationAdmin from '../../../../../types/organization-admin';
import React from 'react';
import Util from '../../../../../utils/util';
import { adminSchema } from '../../../../../utils/validation-schema';
import { useOrganizationApi } from '../../../../../data/organization/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining props for AddAdminDialog component.
 * 
 * @interface Props
 * @property {Organization} organization - The organization object for which the admin is being added.
 * @property {() => void} onClose - Callback function to be called when the dialog is closed.
 * @property {(adminList: Array<OrganizationAdmin>) => void} onAdminSave - Callback function to be called with the * *		updated admin list upon saving.
 */
interface Props extends DialogProps {
	organization: Organization;
	onClose: () => void;
	onAdminSave: (adminList: Array<OrganizationAdmin>) => void;
	adminList: Array<OrganizationAdmin>
}

/**
 * Renders a dialog for adding a new admin to the organization.
 * 
 * @param {Props} props - Component props.
 * @returns {JSX.Element} The AddAdminDialog component.
 */
const AddAdminDialog: React.FC<Props> = (props: Props) => {

	const { organization, adminList, onAdminSave, onClose, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const orgApi = useOrganizationApi();
	const initialValues: OrganizationAdmin = {
		firstName: '',
		lastName: '',
		name: '',
		loginId: '',
		registered: false,
		status: AdminStatus.Pending
	};

	/**
	 * Handles the submission of the admin form.
	 * 
	 * @param {OrganizationAdmin} profile - The admin profile to be added or updated.
	 */
	const onSubmit = (profile: OrganizationAdmin) => {
		if (organization.details.id) {
			const { id, ...detailsWithoutId } = organization.details;
			const updatedOrganization = {
				...organization,
				details: { ...detailsWithoutId },
				admins: UpdateAdminList(profile)
			};
			submitAdminListUpdate(profile, id, updatedOrganization);
		} else {
			onAdminSave(UpdateAdminList(profile));
		}
	};

	/**
	 * Updates the list of admins by adding the new admin's details.
	 * 
	 * @param {OrganizationAdmin} profile - The new admin profile.
	 * @returns {Array<OrganizationAdmin>} Updated list of admins.
	 */
	const UpdateAdminList = (profile: OrganizationAdmin) => {
		const fullName = profile.name || `${profile.firstName ?? ''} ${profile.lastName ?? ''}`.trim();
		const updatedUser: OrganizationAdmin = {
			...profile,
			name: fullName,
		};
		const { firstName, lastName, ...finalUser } = updatedUser;
		const updatedAdminList = [ ...adminList, finalUser ];

		return updatedAdminList;
	};

	/**
	 * Submits the updated organization with the new admin details to the API.
	 * 
	 * @param {OrganizationAdmin} profile - The new admin profile.
	 * @param {string} id - The organization ID.
	 * @param {Organization} org - The updated organization object.
	 */
	const submitAdminListUpdate = (profile: OrganizationAdmin, id: string, org: Organization) => {
		orgApi.updateOrganization(id, org).then((data) => {
			if (data) {
				const addedAdmin = data.admins.find(
					(admin: OrganizationAdmin) => admin.name === `${profile.firstName} ${profile.lastName}`
				);
				if ((addedAdmin && addedAdmin._links && addedAdmin._links.invite) && org.details.status === OrgStatus.Active) {
					const url = addedAdmin._links.invite.href;
					const inviteEndpoint = url.includes(APIConfig.relPath) ? url.split(APIConfig.relPath)[ 1 ] : '';
					submitAdminInvitation(inviteEndpoint);
				} else {
					onAdminSave(UpdateAdminList(profile));
					onClose();
				}
			}
		});
	};

	/**
	 * Sends an invitation to the new admin using the provided invite link.
	 * 
	 * @param {string} inviteLink - The invitation link for the new admin.
	 */
	const submitAdminInvitation = (inviteLink: string) => {
		orgApi.InviteOrganizationAdmin(inviteLink).then(data => {
			if (data) {
				onAdminSave(UpdateAdminList(data));
				onClose();
			}
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={() => onClose()}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{(organization.details.status === OrgStatus.Active) ? t('invite') : t('addAdmin')}
				</Typography>
				<IconButton onClick={() => onClose()}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<Formik
				enableReinitialize
				validationSchema={adminSchema}
				initialValues={initialValues}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.formContainer}>
								<InlineAlert message={Util.getApiError([ INVITE_ADMIN ], orgApi.state.orgState.apiStatus)} />
								<Field
									name='firstName'
									label={t('firstName')}
									placeholder={t('firstName')}
									component={CustomInput}
								/>
								<Field
									name='lastName'
									label={t('lastName')}
									placeholder={t('lastName')}
									component={CustomInput}
								/>
								<Field
									name='loginId'
									label={t('email')}
									placeholder={t('emailAddress')}
									component={CustomInput}
								/>
							</Box>
						</DialogContent>
						<DialogActions>
							<CustomButton
								sx={styles.saveBtn}
								type='submit'
								title={(organization.details.status === OrgStatus.Active) ? t('invite') : t('save')}
								color='primary'
								disabled={!dirty || !isValid}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default AddAdminDialog;