import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { useTheme } from '@mui/material';

const CheckFilledIcon = (props: SvgIconProps) => {

  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1 9.5C1 10.5506 1.20693 11.5909 1.60896 12.5615C2.011 13.5321 2.60028 14.414 3.34315 15.1569C4.08601 15.8997 4.96793 16.489 5.93853 16.891C6.90914 17.2931 7.94943 17.5 9 17.5C10.0506 17.5 11.0909 17.2931 12.0615 16.891C13.0321 16.489 13.914 15.8997 14.6569 15.1569C15.3997 14.414 15.989 13.5321 16.391 12.5615C16.7931 11.5909 17 10.5506 17 9.5C17 8.44943 16.7931 7.40914 16.391 6.43853C15.989 5.46793 15.3997 4.58601 14.6569 3.84315C13.914 3.10028 13.0321 2.511 12.0615 2.10896C11.0909 1.70693 10.0506 1.5 9 1.5C7.94943 1.5 6.90914 1.70693 5.93853 2.10896C4.96793 2.511 4.08601 3.10028 3.34315 3.84315C2.60028 4.58601 2.011 5.46793 1.60896 6.43853C1.20693 7.40914 1 8.44943 1 9.5Z' fill='currentColor' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.33398 9.50043L8.11176 11.2782L11.6673 7.72266' stroke={theme.palette.primary.contrastText} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default CheckFilledIcon;