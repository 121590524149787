import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ReloadIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6909_22371)'>
          <path d='M15.6093 9.41361C15.4441 10.6736 14.939 11.8649 14.1482 12.8597C13.3573 13.8545 12.3106 14.6152 11.1203 15.0603C9.92998 15.5055 8.64095 15.6181 7.39145 15.3863C6.14195 15.1545 4.97911 14.587 4.02766 13.7445C3.07621 12.9021 2.37203 11.8165 1.99065 10.6043C1.60927 9.39201 1.56507 8.09881 1.86279 6.86335C2.16051 5.6279 2.78893 4.49679 3.68064 3.59134C4.57235 2.68589 5.69371 2.04025 6.92447 1.72367C10.3363 0.84862 13.868 2.60485 15.1718 5.87753' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M15.6682 1.5V5.87525H11.293' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6909_22371'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ReloadIcon;