import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BinIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6357_8843)'>
          <path d='M2.44531 4.60938H14.8898' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M7.11328 7.72266V12.3893' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M10.2227 7.72266V12.3893' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M3.22266 4.60938L4.00043 13.9427C4.00043 14.3553 4.16432 14.7509 4.45605 15.0427C4.74777 15.3344 5.14343 15.4983 5.55599 15.4983H11.7782C12.1908 15.4983 12.5864 15.3344 12.8782 15.0427C13.1699 14.7509 13.3338 14.3553 13.3338 13.9427L14.1115 4.60938' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M6.33594 4.61111V2.27778C6.33594 2.0715 6.41788 1.87367 6.56374 1.72781C6.70961 1.58194 6.90744 1.5 7.11372 1.5H10.2248C10.4311 1.5 10.6289 1.58194 10.7748 1.72781C10.9207 1.87367 11.0026 2.0715 11.0026 2.27778V4.61111' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6357_8843'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default BinIcon;