import {
	API_FAILURE,
	CREATE_ORG,
	FEATURE_LIST_SUCCESS,
	FETCH_FEATURE_LIST,
	FETCH_LICENCE_LIST,
	FETCH_ORG_LIST,
	INVITE_ADMIN,
	LICENCE_LIST_SUCCESS,
	ORG_LIST_SUCCESS,
	OrgActionTypes,
	RESET_ERROR_STATE,
	UPDATE_ORG
} from './action-types';

import OrganizationState from '../../types/states/organization-state';

/**
 * Initial state for the organization reducer.
 */
export const orgInitialState: OrganizationState = {
	orgList: [],
	licenseList: [],
	featureList: [],
	apiStatus: undefined
};

/**
 * Organization Reducer
 *
 * This function handles state updates for the organization data based on dispatched actions.
 * 
 * @param {OrganizationState} state - The current state of the organization data.
 * @param {OrgActionTypes} action - The dispatched action object.
 * @returns {OrganizationState} - The updated state of the organization data based on the action.
 */
const organizationReducer = (state: OrganizationState, action: OrgActionTypes): OrganizationState => {
	switch (action.type) {
		case FETCH_ORG_LIST:
		case FETCH_LICENCE_LIST:
		case FETCH_FEATURE_LIST:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case ORG_LIST_SUCCESS:
			return {
				...state,
				orgList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case LICENCE_LIST_SUCCESS:
			return {
				...state,
				licenseList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case FEATURE_LIST_SUCCESS:
			return {
				...state,
				featureList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: action.payload,
					isCompleted: true
				}
			};
			case UPDATE_ORG:
			case INVITE_ADMIN:
			case CREATE_ORG:
				return {
					...state,
					apiStatus: {
						task: action.type,
						isLoading: true,
						data: undefined,
						error: undefined,
						isCompleted: false
					}
				};
			case RESET_ERROR_STATE:
				return {
					...state,
					apiStatus: undefined
				};
		default:
			return state;
	}
};

export default organizationReducer;