import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CircularProgressBar component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    spinnerContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: `rgba(${theme.palette.background.default}, 0.25)`,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#fff',
      zIndex: theme.zIndex.drawer + 1
    },
    spinner: {
      m: Dimens.spacing.sm
    }
  };

  return styles;
};

export default useStyles;
