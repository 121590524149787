import Dimens from '../../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the AddAdminDialog component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {
  const {lg, xl, base} = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        minWidth: Dimens.alertDialogSmallWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('md')]: {
          minWidth: Dimens.alertDialogSmallWidth.sm
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
          height: '100%',
          minHeight: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          p: lg
        }
      },
      '& .MuiDialogContent-root': {
        p: 0,
        minHeight: Dimens.dialogContentMaxHeight,
        maxHeight: Dimens.dialogContentMaxHeight,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          minHeight: 'auto',
          maxHeight: 'none'
        }
      },
      '& .MuiDialogActions-root': {
        p: 0,
        pt: lg,
        borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiButton-colorPrimary': {
          [theme.breakpoints.down('sm')]: {
            margin: 0,
            flex: 1
          }
        }
      }
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.bold
    },
    formContainer: {
      display: 'flex', 
      flex: 1, 
      flexDirection: 'column'
    },
    wrapper: {
      display: 'flex',
      height: 'inherit'
    },
    saveBtn: {
      display: 'flex'
    }
  };

  return styles;
}

export default useStyles;
