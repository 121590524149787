import { apiFailure, initApi, signInSuccess, signOutSuccess } from './actions';
import { differenceInMilliseconds, isAfter, subMilliseconds } from 'date-fns';
import { useCallback, useReducer } from 'react';

import APIConfig from '../../service/api-config';
import APP_NAV from '../../routes/app-nav';
import BaseResponse from '../../types/base-response';
import BearerToken from '../../types/bearer-token';
import ForgetPasswordRequest from '../../types/forget-password-verify-request';
import ForgotPasswordRequest from '../../types/forgot-password-request';
import LoginUtil from '../../utils/login-util';
import PasswordResponse from '../../types/password-response';
import { REFRESH_TOKEN_DEBOUNCE_TIME } from '../../utils/constants';
import SignInRequest from '../../types/sign-in-request';
import SignInResponse from '../../types/sign-in-response';
import Util from '../../utils/util';
import { doPost } from '../../service';
import { signInInitialState } from './reducer';
import signInReducer from './reducer';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

/**
 * Custom hook for handling sign-in and sign-out logic with API calls and state management.
 *
 * This hook utilizes `useReducer` with a custom reducer (`signInReducer`) to manage the
 * sign-in state. It provides functions for performing sign-in, sign-out, and handling
 * failures.
 *
 * This hook provides a centralized and reusable way to manage sign-in/out functionality
 * with API interactions and state management in your React components.
 *
 * @returns {Object} - An object containing functions for sign-in, sign-out, session-refresh and the current state.
 *   - `performSignIn` (func): Function to perform user sign-in.
 *   - `performSignOut` (func): Function to perform user sign-out.
 *   - `refreshToken` (func): Function to refresh access token.
 *   - `state` (Object): The current sign-in state managed by the reducer.
 */
export function useSignInApi() {
	
	const [ state, dispatch ] = useReducer(signInReducer, signInInitialState);
	const navigate = useNavigate();

	/**
	 * Performs user sign-in by making a POST request to the login API endpoint
	 * with encrypted credentials and handling the response accordingly.
	 *
	 * @param {LoginRequest} request - The login request object containing user credentials (email, clientId and password).
	 *
	 * @throws {Error} - Re-throws any errors encountered during the API call or processing.
	 */
  const performSignIn = useCallback(async (request: SignInRequest) => {
    dispatch(initApi());
    try {
      request.clientId = LoginUtil.getClientId();
      request.password = LoginUtil.encryptPassword(request.password || '');
      const response: BaseResponse = await doPost(APIConfig.login, request);
      // dispatch(signInSuccess(bearerToken));
      navigate(APP_NAV.VERIFY, { state: request});
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

	/**
	 * Performs user verification and authentication.
	 *
	 * This function dispatches an action to initialize the API call, then attempts to
	 * authenticate the user using a POST request. Upon successful authentication,
	 * it saves the bearer token, dispatches a sign-in success action, and navigates to
	 * the organization screen. If authentication fails, it dispatches a failure action.
	 *
	 * @param {SignInRequest} request - The sign-in request object containing login ID and password.
	 *
	 * @returns {Promise<void>} - A promise that resolves after the API call completes.
	 */
	const performVerification = useCallback(async (request: SignInRequest) => {
		dispatch(initApi());
		try {
			request.clientId = LoginUtil.getClientId();
			request.password = LoginUtil.encryptPassword(request.password || '');
			const response: SignInResponse = await doPost(APIConfig.verification,	request);
			if (response.bearer_tokens && response.bearer_tokens.length > 0) {
				const bearerToken: BearerToken = response.bearer_tokens[ 0 ];
				LoginUtil.saveSignInResponse(response);
				dispatch(signInSuccess(bearerToken));
				navigate(APP_NAV.ORGANIZATION);
			} else {
				dispatchFailureAction();
			}
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Performs user sign-out by making a POST request to the logout API endpoint
	 * and clearing session data.
	 *
	 * @param {boolean} isSessionTimeOut - Flag indicating if sign-out is due to session timeout.
	 *
	 * @throws {Error} - Re-throws any errors encountered during the API call or processing.
	 */
	const performSignOut = useCallback(async (isSessionTimeOut: boolean) => {
		dispatch(initApi());
		try {
			await doPost(APIConfig.logOut);
			clearSessionData(isSessionTimeOut);
		} catch (error: any) {
			/* eslint-disable-line */
			clearSessionData(isSessionTimeOut, error);
		}
	}, []);

	/**
	 * Automatic Refresh Token Logic
	 *
	 * This code implements logic for automatically refreshing access tokens used for API calls.
	 * It ensures uninterrupted access by proactively obtaining a new access token before the current one expires.
	 * The refresh process considers a debounce time (`REFRESH_TOKEN_DEBOUNCE_TIME`) to avoid excessive refresh requests
	 * close to the expiry and ensure a smooth user experience.
	 */
	const refreshToken = useCallback(async () => {
		try {
			const bearerToken: BearerToken | undefined = LoginUtil.getBearerToken();
			if (bearerToken) {
				// Checking whethere the access token is still valid..
				const accessTokenThreshold = subMilliseconds(Util.UTCtoLocalTime(bearerToken.access_token.expiresAt),
					REFRESH_TOKEN_DEBOUNCE_TIME);
				if (isAfter(accessTokenThreshold, new Date())) {
					refreshTokenScheduler(bearerToken.access_token.expiresAt);
					return;
				}
				// Refresh access token if the refresh token is not expired.
				if (bearerToken.refresh_token && isAfter(Util.UTCtoLocalTime(bearerToken.refresh_token.expiresAt),new Date())) {
					const request: SignInRequest = {
						clientId: LoginUtil.getClientId(),
						loginId: bearerToken.sub
					};
					const response: SignInResponse = await doPost(APIConfig.refreshToken, request);
					if (response.bearer_tokens && response.bearer_tokens.length > 0) {
						const bearerToken: BearerToken = response.bearer_tokens[ 0 ];
						LoginUtil.saveBearerToken(bearerToken);
						refreshTokenScheduler(bearerToken.access_token.expiresAt);
						return;
					}
				}
			}
			clearSessionData(true);
		} catch (error: any) { /* eslint-disable-line */
			clearSessionData(true, error);
		}
	}, []);

	/**
	 * Schedules Refresh Token Call
	 *
	 * This function calculates the appropriate delay for the next refresh attempt based on the provided access token expiry time
	 * and the configured `REFRESH_TOKEN_DEBOUNCE_TIME`. It uses `setTimeout` to schedule the execution of the `refreshToken` function
	 * after the calculated delay. The `REFRESH_TOKEN_DEBOUNCE_TIME` introduces a buffer period before the actual expiry to avoid
	 * excessive refresh requests close to expiry and ensure a smooth user experience.
	 *
	 * @param {string} expiryTimeStr - The expiry time string of the current access token.
	 */
	const refreshTokenScheduler = (expiryTimeStr: string) => {
		const delay = differenceInMilliseconds(Util.UTCtoLocalTime(expiryTimeStr), new Date()) - REFRESH_TOKEN_DEBOUNCE_TIME;
		setTimeout(() => {
			refreshToken();
		}, delay);
	}

	/**
	 * Handles clearing session data (bearer tokens, etc.) and navigating to the sign-in page
	 * based on the `isSessionTimeOut` flag and any potential errors.
	 *
	 * @param {boolean} isSessionTimeOut - Flag indicating if sign-out is due to session timeout.
	 * @param {Error} error - Optional error object if encountered during sign-out.
	 */
	const clearSessionData = (isSessionTimeOut: boolean, error?: any) => { /* eslint-disable-line */
		error ? dispatchFailureAction(error) : dispatch(signOutSuccess());
		LoginUtil.clearAll();
		navigate(APP_NAV.SIGN_IN, {
			state: {isSessionTimeOut: isSessionTimeOut}
		});
	}

	/**
	 * Dispatches an `apiFailure` action with an error message for failure scenarios during sign-in/out.
	 *
	 * @param {Error} error - Optional error object encountered during sign-in/out processes.
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string =	error && error.message ? error.message : t('defaultErrorMsg');
		dispatch(apiFailure(message));
	}

	/**
	 * Handles the forgot password process.
	 *
	 * This function initiates an API request to handle a user's "forgot password" action.
	 * It dispatches an action to indicate the start of an API call, then attempts to send
	 * the "forgot password" request to the appropriate endpoint. If the request is successful,
	 * it prepares the data needed for the next step, which involves verifying the user's identity.
	 * Upon success, the function navigates to the verification screen. If the API request fails,
	 * a failure action is dispatched.
	 *
	 * @param {ForgotPasswordRequest} forgetRequest - The request object containing details required for the forgot password action.
	 *
	 * @returns {Promise<void>} - A promise that resolves after the forgot password process completes.
	 */
	const performForgetPassword = useCallback(
		async (forgetRequest: ForgotPasswordRequest) => {
			dispatch(initApi());
			try {
				const response: BaseResponse = await doPost(APIConfig.forget,	forgetRequest);
				const request: SignInRequest = {
					loginId: forgetRequest.loginId ?? '', // Ensuring loginId is set to a default value if undefined
					clientId: LoginUtil.getClientId(),
					password: ''
				};
				if (response.ok) {
					navigate(APP_NAV.VERIFY, {state: request});
				} else {
					dispatchFailureAction();
				}
			} catch (error: any) { /* eslint-disable-line */
				dispatchFailureAction(error);
			}
		},[]);

	/**
	 * Performs the verification of a user's password reset request.
	 * Dispatches actions to initiate the API call and manage success/error scenarios.
	 *
	 * @param {SignInRequest} signInRequest - The request object containing user login details and verification information.
	 *
	 * @returns {Promise<void>} - Resolves when the verification process is complete.
	 */
	const performForgetPasswordVerification = useCallback(
		async (signInRequest: SignInRequest) => {
			dispatch(initApi());
			try {
				const response: PasswordResponse = await doPost(APIConfig.passVerification,	signInRequest);
				if (response.ok && response.data?.verificationToken) {
					const request: ForgetPasswordRequest = {
						clientId: LoginUtil.getClientId(),
						loginId: signInRequest.loginId,
						verificationCode: signInRequest.verification ?? '',
						verificationToken: response.data.verificationToken
					};
					navigate(APP_NAV.RESET_PASSWORD, {state: request});
				} else {
					dispatchFailureAction();
				}
			} catch (error: any) { /* eslint-disable-line */
				dispatchFailureAction(error);
			}
		}, []);

	/**
	 * Sets a new password for the user after verification.
	 * Dispatches actions to initiate the API call and manage success/error scenarios.
	 *
	 * @param {SetPasswordRequest} request - The request object containing the new password and associated details.
	 *
	 * @returns {Promise<void>} - Resolves when the password setting process is complete.
	 */
	const performSetPassword = useCallback(
		async (request: ForgetPasswordRequest) => {
			dispatch(initApi());
			try {
				request.password = request.password	? LoginUtil.encryptPassword(request.password) : '';
				const response: SignInResponse = await doPost(APIConfig.setPassword, request);
				if (response.bearer_tokens && response.bearer_tokens.length > 0) {
					const bearerToken: BearerToken = response.bearer_tokens[ 0 ];
					LoginUtil.saveBearerToken(bearerToken);
					dispatch(signInSuccess(bearerToken));
					navigate(APP_NAV.ORGANIZATION);
				} else {
					dispatchFailureAction();
				}
			} catch (error: any) { /* eslint-disable-line */
				dispatchFailureAction(error);
			}
		}, []);

	return {
		performSignIn,
		performVerification,
		performSignOut,
		refreshToken,
		performForgetPassword,
		performForgetPasswordVerification,
		performSetPassword,
		state
	};
}
