import { Box } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import LanguageSelector from '../../components/LanguageSelector';
import React from 'react';
import Stack from '@mui/material/Stack';
import useStyles from './styles';

export interface Props {
  children: React.ReactNode;
}

/**
 * Pre-login layout component that serves as a container for login-related components.
 *
 * This component provides a styled wrapper and includes a `LanguageSelector` component
 * for users to choose their preferred language. It takes children as props, allowing
 * for flexible inclusion of various login-related components within the layout.
 * 
 * This component is intended to be used as a base for all pre-login layouts in the application.
 *
 * @props {Props} - Component properties defining child components to be rendered within the layout.
 *
 * @returns {JSX.Element} - The rendered pre-login layout component.
 */
const SignInLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <Grid container sx={styles.wrapper}>
      <Stack sx={styles.langWrapper}>
        <LanguageSelector />
      </Stack>
      {props.children}
    </Grid>
  );
};

export default SignInLayout;
