/**
 * Class representing an application error.
 * 
 * This class provides a structured way to represent errors 
 * that occur within your application. 
 */
export default class AppError {

  /**
   * The error code associated with the error.
   */
  public code: number;

  /**
   * A human-readable message describing the error.
   */
  public message: string;

  /**
   * Creates a new AppError instance.
   * 
   * @param {number} code The error code.
   * @param {string} message The error message.
   */
  constructor(code: number, message: string) {
    this.code = code;
    this.message = message;
  }
}