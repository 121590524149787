import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the InlineAlert component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (isShow = false) => {

  const theme = useTheme();
  const styles = {
    wrapper: {
      width: '100%',
      display: isShow ? 'flex' : 'none',
      pb: Dimens.spacing.md
    },
    alert: {
      borderRadius: Dimens.radius.md,
      p: `0 ${Dimens.spacing.md}`,
      '.MuiAlert-icon': {
        p: `${Dimens.spacing.sm} 0`,
        fontSize: Dimens.icon.md
      },
      '.MuiAlert-message': {
        p: `${Dimens.spacing.sm} 0`,
        alignContent: 'center'
      }
    },
    title: {
      fontWeight: Dimens.fontWeight.extraBold
    },
    msg: {
      ...theme.typography.p1,
      fontWeight: Dimens.fontWeight.semiBold,
      paddingLeft: Dimens.spacing.xxs
    }
  };

  return styles;
};

export default useStyles;
