import Dimens from '../../theme/dimens';

/**
 * useStyles custom hook generates MUI theme dependent styles for the LanguageSelector component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    icon: {
      fontSize: Dimens.icon.sm
    }
  };

  return styles;
};

export default useStyles;