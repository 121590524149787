import React, { useEffect, useState } from 'react';

import { InputBase } from '@mui/material';
import { InputBaseProps } from '@mui/material/InputBase';
import { SEARCH_DEBOUNCE_DELAY } from '../../utils/constants';
import SearchIcon from '../CustomIcons/SearchIcon';
import useStyles from './styles';

type Props = {
  handleChange: (value: string) => void;
} & InputBaseProps;

/**
 * Search Bar Component
 *
 * This component renders a search bar for text input with the following features:
 * - Debounced search handling: Delays triggering the `handleChange` prop by a set time (debounce delay).
 * - Clear button: Allows users to clear the search input.
 * - Material-UI styling with custom classes.
 *
 * @props { Props } - The component properties.
 * @property { (value: string) => void } handleChange - Callback function to handle search term changes.
 * @property { InputBaseProps } - Additional props for customization using Material-UI's InputBase.
 *
 * @returns {JSX.Element} - The rendered JSX element for the search bar.
 */
const SearchBar: React.FC<Props> = (props: Props) => {

  const { handleChange, ...rest } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const styles = useStyles(searchKey);

  // Debounced search handling using useEffect hook
  useEffect(() => {
    // Debounce function to delay execution of handleChange
    const debounce = setTimeout(() => {
      handleChange(searchKey);
    }, SEARCH_DEBOUNCE_DELAY); // Adjust the debounce delay here (in milliseconds)

    // Cleanup function to clear debounce timeout on component unmount or key change
    return () => clearTimeout(debounce);
  }, [searchKey, handleChange]);

  return (
    <InputBase
      {...rest}
      type='search'
      sx={{...styles.inputWrapper, ...rest.sx}}
      inputProps={{
        style: styles.input,
      }}
      value={searchKey}
      onChange={e => setSearchKey(e.target.value)}
      startAdornment={<SearchIcon sx={styles.icon} />}
    />
  );
};

export default SearchBar;
