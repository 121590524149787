import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M3 8.0013L6.33333 11.3346L13 4.66797' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default CheckIcon;