import {
	API_FAILURE,
	INIT_API,
	SIGN_IN_SUCCESS,
	SIGN_OUT_SUCCESS,
	SignInActionTypes
} from './action-types';

import SignInState from '../../types/states/sign-in-state';

/**
 * Initial state for the sign-in reducer.
 *
 * This defines the initial values for properties like `isSignedIn` (indicates user sign-in status)
 * and `apiStatus` (an object containing details about the current API call state).
 */
export const signInInitialState: SignInState = {
	isSignedIn: false,
	apiStatus: undefined
};

/**
 * Reducer function for managing sign-in state updates based on dispatched actions.
 *
 * This function takes the current state (`SignInState`) and an action (`SignInActionTypes`)
 * as arguments and returns a new state object based on the action type.
 *
 * - `INIT_API`: Sets the `apiStatus` to indicate an API call has begun (isLoading: true).
 * - `SIGN_IN_SUCCESS`: Updates `isSignedIn` to true and sets `apiStatus` to reflect successful sign-in
 *   with the payload data (likely containing user information).
 * - `SIGN_OUT_SUCCESS`: Updates `isSignedIn` to false and sets `apiStatus` to reflect successful sign-out
 *   with the payload data (potentially empty).
 * - `API_FAILURE`: Sets `apiStatus` to reflect an API call failure with the error message from the payload.
 * - `default`: Returns the current state if the action type is not recognized.
 *
 * @param {SignInState} state - The current sign-in state.
 * @param {SignInActionTypes} action - The dispatched action object.
 * @returns {SignInState} - The updated sign-in state.
 */
const signInReducer = (state: SignInState, action: SignInActionTypes): SignInState => {
  switch (action.type) {
		case INIT_API:
			return {
				...state,
				apiStatus: {
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case SIGN_IN_SUCCESS:
			return {
				...state,
				isSignedIn: true,
				apiStatus: {
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case SIGN_OUT_SUCCESS:
			return {
				...state,
				isSignedIn: false,
				apiStatus: {
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					isLoading: false,
					data: undefined,
					error: action.payload,
					isCompleted: true
				}
			};
		default:
			return state;
  }
};

export default signInReducer;