import { useLayoutEffect, useState } from 'react';

/**
 * Custom hook to track the size of a container element.
 *
 * @param {React.MutableRefObject<HTMLElement | null>} containerRef - A ref to the container element.
 * 
 * @returns {number[]} An array containing the width and height of the container.
 */
export function useContainerSize(containerRef: React.MutableRefObject<HTMLElement | null>) {

  const [size, setSize] = useState([0, 0]);

  /**
   * Updates the container size on layout changes and window resize.
   */
  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container)
      return;

    const updateSize = () => setSize([container.offsetWidth, container.offsetHeight]);
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [containerRef]);

  return size;
}