import { Box, Switch, Typography } from '@mui/material';

import AdminIcon from '../../../../../components/CustomIcons/AdminIcon';
import Feature from '../../../../../types/feature';
import { FeatureType } from '../../../../../utils/constants';
import MessageIcon2 from '../../../../../components/CustomIcons/MessageIcon2';
import React from 'react';
import SettingsIcon from '../../../../../components/CustomIcons/SettingsIcon';
import useStyles from './styles';

interface Props {
  feature: Feature;
}

/**
 * Renders a card representing a feature with an icon, label, and switch.
 *
 * @param {Props} props - Component props containing the `feature` object.
 * 
 * @returns {JSX.Element} JSX element representing the FeatureCard component.
 */
const FeatureCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.feature);

  /**
   * Retrieves the appropriate icon based on the feature label.
   *
   * @param {string} label - The feature label.
   * 
   * @returns {JSX.Element} The corresponding icon.
   */
  const getIcon = (label: string) => {
    switch (label) {
      case FeatureType.Admin:
        return <AdminIcon />;
      case FeatureType.System:
        return <SettingsIcon />;
      case FeatureType.Messaging:
        return <MessageIcon2 />
      default:
        return <MessageIcon2 />;
    }
  }

  return (
    <Box sx={styles.itemWrapper}>
      {getIcon(props.feature.label)}
      <Typography variant='subheading' sx={styles.label}>{props.feature.label}</Typography>
      <Switch disableRipple sx={styles.iosSwitch} checked={props.feature.value} disabled={props.feature.disabled} />
    </Box>
  );
};

export default FeatureCard;

