import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CustomDatePicker component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	
	const styles = {
		wrapper: {
      flexDirection: 'column',
      display: 'flex',
      flex: 1,
			pb: Dimens.spacing.base
    },
		header: {
			flexDirection: 'row',
			pb: Dimens.spacing.xs
		},
		subLabel: {
			pl: Dimens.spacing.xxs,
			color: theme.palette.text.secondary,
			fontWeight: Dimens.fontWeight.semiBold
		},
		placeholder: {
			color: `${theme.palette.text.secondary} !important`,
			transition: 'none',
			transform: 'none',
			p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
			'&.MuiFormLabel-filled': {
				display: 'none'
			}
		},
		datePicker: {
			minHeight: 0,
			height: 'inherit',
			borderRadius: Dimens.radius.md,
			'& .MuiInputLabel-shrink': {
				display: 'none'
			},
			'& .MuiOutlinedInput-root': {
				minHeight: 0,
				p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
				display: 'flex',
				alignItems: 'center',
				backgroundColor: theme.palette.background.default,
				borderRadius: Dimens.radius.md,
				borderWidth: Dimens.inputBorderWidth
			},
			'& .MuiOutlinedInput-input': {
				minHeight: 0,
				p: 0,
				display: 'flex',
				alignItems: 'center'
			},
      '& fieldset': {
				borderWidth: Dimens.inputBorderWidth,
        borderColor: theme.palette.background.default,
				top: 0,
				p: 0,
				'legend': {
					display: 'none'
				}
      },
			'&:hover fieldset': {
				borderColor: 'none',
				borderWidth: 0
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: Dimens.inputBorderWidth
			},
			'&.Mui-error fieldset': {
				borderWidth: Dimens.inputBorderWidth,
				borderColor: theme.palette.error.main
			},
			'&:hover .Mui-error fieldset': {
				borderWidth: Dimens.inputBorderWidth,
				borderColor: theme.palette.error.main
			}
    },
		caretIcon: {
			fontSize: Dimens.icon.sm,
			mr: Dimens.spacing.xxs,
			color: `${theme.palette.text.primary} !important`
		},
    menuList: {
      p: 0
    },
    paper: {
      boxShadow: `0 ${Dimens.spacing.xxs} ${Dimens.spacing.xs} 0 ${Colors.shadow}`,
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md
    },
    menuItem: {
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
			justifyContent: 'space-between',
      minWidth: Dimens.menuItemMinWidth,
			backgroundColor: theme.palette.background.paper,
			'&:hover': {
				backgroundColor: theme.palette.background.default,
			},
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.paper,
				'&:hover': {
					backgroundColor: theme.palette.background.default,
				}
			},
			'svg': {
				...commonStyles.smIcon
			}
		},
		divider: {
			my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
		},
		fieldError: {
			...theme.typography.footer,
			m: 0,
			pt: Dimens.spacing.xs,
			color: theme.palette.error.main
		}
	};

	return styles;
};

export default useStyles;