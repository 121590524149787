import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the OTPInput component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    inputWrapper: { 
      display: 'inline-block',
      position: 'relative'
    },
    inputBase: {
      width: Dimens.singleInput.width,
      height: Dimens.singleInput.height,
      marginRight: Dimens.spacing.xs,
      backgroundColor: theme.palette.background.default,
      borderRadius: Dimens.radius.md,
      justifyContent: 'center'
    },
    noMargin: {
      marginRight: 0
    },
    inputProps: {
      ...theme.typography.h4,
      fontWeight: Dimens.fontWeight.bold,
      textAlign: 'center',
      padding: 0
    },
    inputFocusLine: {
      position: 'absolute',
      marginTop: Dimens.spacing.xs,
      width: Dimens.singleInput.height,
      borderBottom: `${Dimens.spacing.xxs} solid ${theme.palette.primary.main}`,
      borderRadius: Dimens.radius.md
    }
  };

  return styles;
};

export default useStyles;
