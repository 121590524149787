import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckCircleOutlineIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1 9.97656C1 11.0271 1.20693 12.0674 1.60896 13.038C2.011 14.0086 2.60028 14.8905 3.34315 15.6334C4.08601 16.3763 4.96793 16.9656 5.93853 17.3676C6.90914 17.7696 7.94943 17.9766 9 17.9766C10.0506 17.9766 11.0909 17.7696 12.0615 17.3676C13.0321 16.9656 13.914 16.3763 14.6569 15.6334C15.3997 14.8905 15.989 14.0086 16.391 13.038C16.7931 12.0674 17 11.0271 17 9.97656C17 8.92599 16.7931 7.8857 16.391 6.9151C15.989 5.94449 15.3997 5.06258 14.6569 4.31971C13.914 3.57684 13.0321 2.98756 12.0615 2.58553C11.0909 2.18349 10.0506 1.97656 9 1.97656C7.94943 1.97656 6.90914 2.18349 5.93853 2.58553C4.96793 2.98756 4.08601 3.57684 3.34315 4.31971C2.60028 5.06258 2.011 5.94449 1.60896 6.9151C1.20693 7.8857 1 8.92599 1 9.97656Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.33398 9.977L8.11176 11.7548L11.6673 8.19922' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default CheckCircleOutlineIcon;