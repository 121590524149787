import { PaletteMode } from '@mui/material'
import { ThemeMode } from '../utils/constants'

/**
 * Color mode manager utility.
 *
 * This object provides functions to manage the application's color mode
 * using localStorage.
 */
export const colorModeManager = {
  
  /**
   * Retrieves the current color mode preference from localStorage.
   *
   * If no preference is found in localStorage, the system's color scheme is used.
   * The system's color scheme is determined using `window.matchMedia` to detect
   * whether the user prefers a dark or light mode. Defaults to 'light' mode if no
   * system preference or localStorage setting is found.
   *
   * @returns {PaletteMode} The current color mode ('dark' or 'light').
   */
  get: (): PaletteMode => {
    let systemThemeMode = ThemeMode.Light
    if (window) {
      systemThemeMode = window.matchMedia('(prefers-color-scheme: dark)').matches? ThemeMode.Dark : ThemeMode.Light }
    const val = localStorage.getItem('@aurora-app-color-mode')

    return (val ?? systemThemeMode) === 'dark' ? 'dark' : 'light'
  },

  /**
   * Sets the color mode preference in localStorage.
   *
   * @param {string} value The desired color mode ('dark' or 'light').
   */
  set: (value: string) => {
    localStorage.setItem('@aurora-app-color-mode', value);
  }
};
