/**
 * useStyles custom hook generates MUI theme dependent styles for the Main component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const styles = {
		root: {
			height: 'inherit'
		},
		contentWrapper: {
			height: 'inherit',
			display: 'flex',
			flexDirection: 'column'
		},
		content: {
			height: 'inherit',
			overflow: 'hidden'
		}
	};

	return styles;
};

export default useStyles;