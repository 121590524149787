import MenuData from '../types/ui/menu-data';
import OrgTab from '../types/ui/org-tab';
import Organization from '../types/organization';
import { t } from 'i18next';

// General App Constants
export const MAX_SINGLE_LINE_CHAR_COUNT = 64;
export const MAX_MULTI_LINE_CHAR_COUNT = 400;
export const MULTI_LINE_MAX_ROWS = 4;
export const TOAST_DURATION = 5000;
export const SEARCH_DEBOUNCE_DELAY = 500;

// Login Storage keys
export const KEY_APP_CLIENT_ID = 'app_client_id';
export const KEY_LOGIN_INFO = 'login_info';
export const KEY_LAST_ACTIVE_TIME = 'last_active_time';
export const KEY_ADMIN_INFO = 'admin_info';

// App Storage keys
export const KEY_APP_LANGUAGE = 'app_language';

// Encryption and decryption credentials
export const LOGIN_STORAGE_IV = 'xB%{W0gKe#8KDN$3tD#WCgi;!Ry0xMwB';
export const LOGIN_STORAGE_SECRET = 'ZTSz7SK0dmN639!d';
export const APP_STORAGE_IV = 'xB%{W0gKe#8KDN$3tD#WCgi;!Ry0xMwB';
export const APP_STORAGE_SECRET = 'ZTSz7SK0dmN639!d';
export const PASSWORD_IV = 'RandomInitVector';
export const PASSWORD_SECRET = 'my_super_secret_';

// Session Handling Constants
export const SESSION_TIME_OUT = 20 * 60 * 1000; // 20 Minutes
export const SESSION_DEBOUNCE_TIME = 500;
export const REFRESH_TOKEN_DEBOUNCE_TIME = 15000; // 15 Seconds

// Sign In Page constants
export const OTP_LENGTH = 6;
export const PWD_MIN_LENGTH = 10;

// Regex Patterns
export const REGEX_PATTERN_LOWER_CASE = /[a-z]/;
export const REGEX_PATTERN_UPPER_CASE = /[A-Z]/;
export const REGEX_PATTERN_NUMBER = /\d/;
export const REGEX_PATTERN_PWD_SPECIAL_CHAR = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;

// Date Patterns
export const DATE_PATTERN_DD_MM_YYYY_DOT = 'dd.MM.yyyy';

//ThemeMode maps to the MUI PaletteMode
export const enum ThemeMode {
  System = 'system',
  Light = 'light',
  Dark = 'dark',
}
// Language preferences supported by the application.
export const APP_DEFAULT_LANGUAGE = 'en';
export const languageList = [
  {
    id: 'en',
    value: 'English'
  },
  {
    id: 'fr',
    value: 'French'
  },
  {
    id: 'de',
    value: 'German'
  },
  {
    id: 'es',
    value: 'Spanish'
  }
];

// Organization Screen Constants
export const enum OrgStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Draft = 'DRAFT'
}
export const INIT_ORGANIZATION: Organization = {
  details: {
    name: '',
    domain: '',
    enabled: false,
    description: '',
    expiryAt: '',
    status: OrgStatus.Draft
  },
  admins: [],
  services: []
};
export const TAB_DETAIL = '1';
export const TAB_ADMINS = '2';
export const TAB_FEATURES = '3';
export const TAB_LOGS = '4';
export const TAB_BACK_UP = '5';
// This is written as function in order to address the translation.
// Otherwise translation will not work;
export const orgTabs = (): Array<OrgTab> => ([
  {
    id: TAB_DETAIL,
    label: t('details')
  },
  {
    id: TAB_ADMINS,
    label: t('admins')
  },
  {
    id: TAB_FEATURES,
    label: t('features')
  },
  {
    id: TAB_LOGS,
    label: t('logs')
  },
  {
    id: TAB_BACK_UP,
    label: t('backUp')
  }
]);
export const getOrgStatusMenu = (): Array<MenuData> => ([
  {
    id: OrgStatus.Active,
    value: t('active'),
    isChecked: true
  },
  {
    id: OrgStatus.Inactive,
    value: t('inActive'),
    isChecked: true
  },
  {
    id: OrgStatus.Draft,
    value: t('draft'),
    isChecked: true
  }
]);

export const enum FeatureType {
  Admin = 'Admin',
  System = 'System',
  Messaging = 'Messaging'
}

export const enum AdminStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export const AppearanceMenu = (): Array<MenuData> => [
  {
    id: ThemeMode.System,
    value: t('system'),
    isChecked: false
  },
  {
    id: ThemeMode.Light,
    value: t('light'),
    isChecked: false
  },
  {
    id: ThemeMode.Dark,
    value: t('dark'),
    isChecked: false
  }
]