import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CircularProgressBar from '../../components/CircularProgressBar';
import CustomButton from '../../components/CustomButton';
import InlineAlert from '../../components/InlineAlert';
import LogoHeader from '../../components/LogoHeader';
import OTPInput from '../../components/OTPInput';
import { OTP_LENGTH } from '../../utils/constants';
import SignInLayout from '../../layouts/SignInLayout';
import SignInRequest from '../../types/sign-in-request';
import { Store } from '../../store/store';
import logo from '../../assets/logo.png';
import { useLocation } from 'react-router-dom';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Verification screen component for entering a one-time password (OTP) code.
 *
 * This component renders within the `SignInLayout` and displays a logo, verification message,
 * OTP input field, and a submit button. It utilizes the `OTPInput` component for OTP entry,
 * disables the submit button if the entered code doesn't match the specified OTP length,
 * and currently logs the entered code to the console upon submission (replace this with
 * actual verification logic).
 *
 * @returns {JSX.Element} - The rendered verification screen component.
 */
const VerificationScreen: React.FC = () => {

  const styles = useStyles();
  const { t } = useTranslation();
  const {dispatch} = React.useContext(Store);
  const location = useLocation();
  const signInRequest: SignInRequest = location.state;
  const [verificationCode, setVerificationCode] = useState<string>('');
  const signInApi = useSignInApi();

  /**
   * Initial check to determine if `signInRequest` is defined.
   * If it is not defined, the effect exits early without performing any actions.
   * 
   * This effect currently does not include any further logic but serves as a placeholder
   * for potential future updates based on the value of `signInRequest`.
   */
  useEffect(() => {
    if (!signInRequest) {
      return;
    }
  }, []);

  /**
   * Handles the form submission event for sign-in verification.
   * 
   * This function prevents the default form submission behavior,
   * updates the `signInRequest` with the provided verification code,
   * and calls the appropriate API method based on the presence of a password:
   * - If a password is present, it calls `performVerification`.
   * - If no password is provided, it calls `performForgetPasswordVerification`.
   * 
   * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
   */
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    signInRequest.verification = verificationCode;
    if (signInRequest.password) {
      signInApi.performVerification(signInRequest);
    } else {
      signInApi.performForgetPasswordVerification(signInRequest);
    }
  }

  return (
    <SignInLayout>
      <Box sx={styles.innerWrapper}>
        <LogoHeader heading={t('verifyYou')} subHeading={`${t('verifyCodeMsg')}${signInRequest.loginId}`} />
        <form onSubmit={e => onSubmit(e)}>
        <InlineAlert message={signInApi.state.apiStatus?.error} />
          <OTPInput length={OTP_LENGTH} value={verificationCode} onChange={setVerificationCode} />
          <Box sx={styles.spacer} />
          <CustomButton
            title={t('signIn')}
            color='primary'
            type='submit'
            disabled={verificationCode.length !== OTP_LENGTH}
            fullWidth />
        </form>
      </Box>
      <CircularProgressBar show={false} />
    </SignInLayout>
  );
};

export default VerificationScreen;
