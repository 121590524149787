import { Box, Tab } from '@mui/material';
import { INIT_ORGANIZATION, OrgStatus, TAB_ADMINS, TAB_BACK_UP, TAB_DETAIL, TAB_FEATURES, TAB_LOGS, orgTabs } from '../../../utils/constants';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AdminsTab from './AdminsTab';
import BackUpTab from './BackUpTab';
import DetailTab from './DetailTab';
import FeaturesTab from './FeaturesTab';
import LogsTab from './LogsTab';
import OrgTab from '../../../types/ui/org-tab';
import Organization from '../../../types/organization';
import OrganizationAdmin from '../../../types/organization-admin';
import OrganizationDetail from '../../../types/organization-detail';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { useOrganizationApi } from '../../../data/organization/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Provides a tabbed interface for creating or editing organizations.
 *
 * - Fetches organization data from the route location state on component mount.
 * - Determines edit/create mode based on the presence of an organization ID.
 * - Defines available organization detail tabs based on edit mode (some tabs might be hidden in create mode).
 * - Renders the appropriate tab content based on the selected tab (separate components for each detail section).
 *
 * @returns {JSX.Element} - The rendered organization detail screen component with its layout and functionalities.
 */
const OrganizationDetailScreen: React.FC = () => {

	const styles = useStyles();
	const { t } = useTranslation();
	const location = useLocation();
	const [ selectedTab, setSelectedTab ] = React.useState(TAB_DETAIL);
	const orgApi = useOrganizationApi();
	const orgInitialValue: Organization = location.state || INIT_ORGANIZATION;
	const [ organization, setOrganization ] = useState<Organization>(orgInitialValue);
	const isEdit = Boolean(organization.details.id);

	/**
	 * Fetches and sets the organization details on component mount or when `orgInitialValue` changes.
	 * 
	 * @param {Organization | undefined} orgInitialValue - Initial organization details object.
	 */
	useEffect(() => {
		handleInitialOrganization(orgInitialValue);
		setSelectedTab(TAB_DETAIL);
	}, [ orgInitialValue ]);

	/**
	 * Effect to save the organization if it does not have an ID.
	 */
	useEffect(() => {
		// This will run after the organization state has been updated.
		if (organization && !organization.details.id) {
			saveOrganisation();
		}
	}, [ organization ]);

	/**
	 * Handles initial organization data processing based on the `orgInitialValue` prop.
	 * 
	 * @param {Organization | undefined} orgInitialValue - Optional initial organization details object.
	 */
	const handleInitialOrganization = (orgInitialValue?: Organization) => {
		if (orgInitialValue && orgInitialValue.details) {
			if (orgInitialValue.details.id && !orgInitialValue.isFetched) {
				orgApi.fetchOrganizationDetail(orgInitialValue.details.id).then((data) => {
					if (data) {
						setOrganization(data);
					}
				});
			} else {
				setOrganization(orgInitialValue);
			}
		}
	};

	/**
	 * Handles changes in the selected tab.
	 * Updates the state variable `value` to reflect the newly selected tab.
	 * 
	 * @param {React.SyntheticEvent} event - The event object triggered by the tab change.
	 * @param {string} newValue - The ID of the newly selected tab.
	 */
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};

	/**
	 * Defines the available organization detail tabs based on edit mode.
	 * 
	 * @returns {Array<OrgTab>} - Array of organization detail tabs.
	 */
	const getTabList = (): Array<OrgTab> => {

		return isEdit ? orgTabs() : orgTabs().slice(0, 3);
	};

	/**
	* Handles changes in the details tab.
	* 
	* @param {OrganizationDetail} orgDetails - Organization basic details.
	*/
	const handleOrgDetailChange = (orgDetails: OrganizationDetail) => {
		const updatedOrganization = {
			...organization,
			details: {
				...orgDetails,
			},
		};
		if (updatedOrganization.details.id) {
			// Update organization
			const { id, ...detailsWithoutId } = updatedOrganization.details;
			const org = {
				...updatedOrganization,
				details: { ...detailsWithoutId }
			};
			handleUpdateOrganisation(id, org);
		} else {
			// save org details data
			setOrganization(updatedOrganization);
		}
	};

	/**
	 * Updates the organization admin list with new admins.
	 *
	 * @param {Array<OrganizationAdmin>} adminList - New list of organization admins.
	 */
	const handleAdminListChange = (adminList: Array<OrganizationAdmin>) => {
		const updatedAdmins = [ ...organization.admins, ...adminList ];
		const uniqueAdmins = Array.from(new Map(updatedAdmins.map(admin => [ admin.loginId, admin ])).values());
		setOrganization(prevOrganization => {
			const updatedOrganization = {
				...prevOrganization,
				admins: uniqueAdmins,
			};

			return updatedOrganization;
		});
	};

	/**
	* Validates the organization data.
	*
	* @param {Organization} org - The organization object to validate.
	* @returns {boolean} - Returns true if the organization is valid, false otherwise.
	*/
	const isValidOrganization = (org: Organization): boolean => {

		return (Boolean(org.details.name) && Array.isArray(org.admins) && org.admins.length > 0);
	};

	/**
	 * Saves the organization to the API if it is valid.
	 *
	 * This function first checks if the current organization state is valid by calling the `isValidOrganization` method.
	 * The organization is considered valid if it meets the following criteria:
	 * - The organization has a name that is not empty.
	 * - There is at least one admin associated with the organization.
	 *
	 * If the organization is valid, an API call is made to create a new organization entry using the organization data.
	 * @returns {void} - This function does not return a value; it performs an asynchronous operation to save the organization.
	 */
	const saveOrganisation = () => {
		if (isValidOrganization(organization)) {
			orgApi.createOrganization(organization)
				.then((data) => {
					if (data) {
						setOrganization(data);
					}
				});
		}
	};

	/**
	 * Updates the status of the organization based on its current state.
	 *
	 * The function checks the current status of the organization and changes it in a sequential manner:
	 * - If the current status is 'Draft', it updates the status to 'Active'.
	 * - If the current status is 'Active', it updates the status to 'Inactive'.
	 * - If the current status is neither of those, it defaults back to 'Draft'.
	 * 
	 * After determining the new status, the organization details are updated, and an API call is made to
	 * save the updated organization status. If the API returns new data, the organization state is updated
	 * accordingly.
	 *
	 * @returns {void} - This function does not return a value; it updates the organization state directly.
	 */
	const handleOrgStatusUpdate = () => {

		if (!organization.details.id) return;

		const { id, status, ...detailsWithoutId } = organization.details;
		let newStatus;
		switch (status) {
			case OrgStatus.Draft:
				newStatus = OrgStatus.Active;
				break;
			case OrgStatus.Active:
				newStatus = OrgStatus.Inactive;
				break;
			default:
				newStatus = OrgStatus.Draft;
				return; // Exit if the status is unknown
		}

		const updatedOrganization = {
			...organization,
			details: {
				...detailsWithoutId,
				enabled: true,
				status: newStatus,
			},
		};
		handleUpdateOrganisation(organization.details.id, updatedOrganization);
	};

	/**
	 * Updates an organization by its ID and updates the state with the new data.
	 * 
	 * @param {string} id - The unique identifier of the organization to update.
	 * @param {Organization} org - The organization object containing updated data.
	 */
	const handleUpdateOrganisation = (id: string, org: Organization) => {
		
		orgApi.updateOrganization(id, org).then((data) => {
			if (data) {
				setOrganization(prevOrganization => ({
					...prevOrganization,
					details: {
						...prevOrganization.details,
						status: data.details.status
					}
				}));
			}
		});
	};

	/**
	 * Renders the appropriate tab content based on the provided tab ID.
	 * 
	 * @param {string} tabId - The ID of the tab to render content for.
	 * @returns {JSX.Element} - The JSX element representing the tab content.
	 */
	const getTabPanel = (tabId: string) => {
		switch (tabId) {
			case TAB_DETAIL:
				return <DetailTab organization={organization} handleOrgDetailChange={handleOrgDetailChange} onOrgActivate={handleOrgStatusUpdate} />;
			case TAB_ADMINS:
				return <AdminsTab organization={organization} handleAdminListChange={handleAdminListChange} />;
			case TAB_FEATURES:
				return <FeaturesTab />;
			case TAB_LOGS:
				return <LogsTab />;
			case TAB_BACK_UP:
				return <BackUpTab />;
		}
	};

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.innerWrapper}>
				<Typography variant='h4' sx={styles.heading}>{isEdit ? organization.details.name : t('createOrg')}</Typography>
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={handleChange} sx={styles.tabList}>
						{
							getTabList().map((item: OrgTab, index: number) => (
								<Tab key={`org_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{
							getTabList().map((item: OrgTab, index: number) => (
								item.id === selectedTab && (
									<TabPanel key={`org_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
										{getTabPanel(item.id)}
									</TabPanel>
								)
							))
						}
					</Box>
				</TabContext>
			</Box>
		</Box>
	);
};

export default OrganizationDetailScreen;
