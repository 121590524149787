import { Alert, AlertColor, Collapse, CollapseProps, Typography } from '@mui/material';

import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface InlineAlertProps extends CollapseProps {
  /**
   * The title of the alert.
   * @default Error
   */
  title?: string,
  /**
   * The message of the alert.
   * @default undefined
   */
  message?: string,
  /**
   * If set alert theme and icon will change.
   * @default error
   */
  severity?: AlertColor;
}

/**
 * Reusable component for displaying an inline alert message with title and severity.
 * 
 * @props Custom props
 *  - title (string, optional): Title for the alert message. Defaults to "error" from translation if not provided.
 *  - message (string, optional): Content of the alert message. The component will collapse if no message is provided.
 *  - severity (AlertColor, optional): Severity level of the alert (e.g., "success", "warning", "error"). Defaults to "error" if not provided.
 * 
 * @returns {JSX.Element} - The rendered inline alert component.
 */
const InlineAlert: React.FC<InlineAlertProps> = (props: InlineAlertProps) => {

  const styles = useStyles(Boolean(props.message));
  const { t } = useTranslation();

  return (
    <Collapse in={Boolean(props.message)} sx={{...styles.wrapper, ...props.sx}}>
      <Alert variant='filled' severity={props.severity || 'error'} sx={styles.alert}>
        <Typography variant='p1' sx={styles.title}>
          {props.title || t('error')}:
          <span style={styles.msg}>{props.message}</span>
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default InlineAlert;