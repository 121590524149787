import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useRef } from 'react';

import FeatureCard from './FeatureCard';
import { Masonry } from '@mui/lab';
import { useContainerSize } from '../../../../hooks/use-container-size';
import { useOrganizationApi } from '../../../../data/organization/api';
import useStyles from './styles';

/**
 * Renders the "Features" tab displaying a grid of feature cards.
 *
 * @returns {JSX.Element} JSX element representing the FeaturesTab component.
 */
const FeaturesTab: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useContainerSize(containerRef);
  const numOfColumns = Math.floor(width / 200);
  const orgApi = useOrganizationApi();

  return (
    <Box sx={styles.innerWrapper}>
      <Grid sx={styles.contentWrapper} ref={containerRef}>
        <Masonry columns={numOfColumns === 0 ? 1 : numOfColumns} spacing={isMobile ? 1 : 2} sx={styles.content} sequential>
          {orgApi.state.orgState.featureList.map((feature, index) => (
            <FeatureCard key={`org-feature-${index}`} feature={feature} />
          ))}
        </Masonry>
      </Grid>
    </Box>
  );
};

export default FeaturesTab;