import { Navigate, Route, Routes } from 'react-router-dom';

import APP_NAV from './app-nav';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import LoginUtil from '../utils/login-util';
import Main from '../layouts/AppLayout/Main';
import Minimal from '../layouts/AppLayout/Minimal';
import OrganizationScreen from '../screens/OrganizationScreen';
import React from 'react';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import SignInScreen from '../screens/SignInScreen';
import VerificationScreen from '../screens/VerificationScreen';

interface Props {
  component: React.FC;
  isProtected: boolean;
}

/**
 * AppRoutes component defines the application routing logic using React Router.
 * 
 * It utilizes a custom AppLayout component to handle layout rendering based on login state and route protection.
 * Props:
 *  - component: React component to be rendered within the layout.
 *  - isProtected: boolean indicating if the route requires authentication.
 */
const AppRoutes = () => {

  const AppLayout = (props: Props) => {

    const { component: Component } = props;
    const isLoggedIn = Boolean(LoginUtil.getBearerToken());

    if (isLoggedIn) {
      if (props.isProtected) {
        return (<Main> <Component /> </Main>);
      } else {
        return (<Navigate to={`${APP_NAV.ORGANIZATION}`} replace />);
      }
    } else {
      if (props.isProtected) {
        return (<Navigate to={APP_NAV.SIGN_IN} replace />);
      } else {
        return (<Minimal> <Component /> </Minimal>);
      }
    }
  };

  return (
    <Routes>
      <Route path={APP_NAV.SIGN_IN} element={<AppLayout component={SignInScreen} isProtected={false} />} />
      <Route path={APP_NAV.FORGOT_PASSWORD} element={<AppLayout component={ForgotPasswordScreen} isProtected={false} />} />
      <Route path={APP_NAV.VERIFY} element={<AppLayout component={VerificationScreen} isProtected={false} />} />
      <Route path={APP_NAV.RESET_PASSWORD} element={<AppLayout component={ResetPasswordScreen} isProtected={false} />} />
      <Route path={`${APP_NAV.ORGANIZATION}/*`} element={<AppLayout component={OrganizationScreen} isProtected={true} />} />
      <Route path='/*' element={<AppLayout component={SignInScreen} isProtected={false} />} />
      <Route path='/' element={<Navigate to={APP_NAV.SIGN_IN} />} />
    </Routes>
  );
};

export default AppRoutes;
