import { PWD_MIN_LENGTH, REGEX_PATTERN_LOWER_CASE, REGEX_PATTERN_NUMBER, REGEX_PATTERN_PWD_SPECIAL_CHAR, REGEX_PATTERN_UPPER_CASE } from './constants';

import APIStatus from '../types/api-status';
import ValidationItem from '../types/validation-item';
import { format } from 'date-fns';
import { t } from 'i18next';

/**
 * Utility class for various helper functions.
 */
export default class Util {

  /**
   * Converts a UTC time string to a local time Date object.
   * 
   * @param {string} utcTimeString The UTC time string to convert.
   * @returns {Date} The converted local time Date object.
   */
  public static UTCtoLocalTime(utcTimeString: string): Date {
    const utcDate = new Date(utcTimeString);
    const localTime = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));

    return localTime;
  }

  /**
   * Formats a UTC date object into a human-readable string using a specified pattern.
   * 
   * This function likely utilizes an external library for date formatting (date-fns).
   *
   * @param {Date} utcDate - The UTC date object to be formatted.
   * @param {string} pattern - The desired format pattern for the output string (e.g., "DD-MM-YYYY", "YYYY-MM-DD hh:mm").
   * @returns {string} - The formatted date string based on the provided pattern, representing the local time equivalent of the UTC date.
   */
  public static formatUTCtoLocal(utcDate: Date, pattern: string): string {

    return format(utcDate, pattern);
  }

  /**
   * Checks password validation status against different criteria.
   *
   * This public static function takes a password string and returns an array of `ValidationItem` objects.
   * Each `ValidationItem` object has a `label` (translated message) and an `isValid` flag
   * indicating if the password meets the corresponding criteria. The criteria include:
   *  - Minimum length (defined by `PWD_MIN_LENGTH`)
   *  - Lowercase character presence (using `REGEX_PATTERN_LOWER_CASE`)
   *  - Uppercase character presence (using `REGEX_PATTERN_UPPER_CASE`)
   *  - Number presence (using `REGEX_PATTERN_NUMBER`)
   *  - Special character presence (using `REGEX_PATTERN_PWD_SPECIAL_CHAR`)
   *
   * This function can be used to display password validation feedback to the user
   * based on the returned `ValidationItem` objects.
   *
   * @param {string} password - The password string to validate.
   * @returns {Array<ValidationItem>} - An array of objects with validation status details.
   */
  public static getPasswordValidationStatus(password: string): Array<ValidationItem> {

    return [
      {
        label: t('pwdMinLength'),
        isValid: password.length >= PWD_MIN_LENGTH
      },
      {
        label: t('pwdLowerCase'),
        isValid: REGEX_PATTERN_LOWER_CASE.test(password)
      },
      {
        label: t('pwdUpperCase'),
        isValid: REGEX_PATTERN_UPPER_CASE.test(password)
      },
      {
        label: t('pwdNumber'),
        isValid: REGEX_PATTERN_NUMBER.test(password)
      },
      {
        label: t('pwdSpecialChar'),
        isValid: REGEX_PATTERN_PWD_SPECIAL_CHAR.test(password)
      }
    ];
  }

  /**
   * Generates a random client ID string.
   *
   * This function uses the window.crypto API to generate a cryptographically
   * secure random byte array. It then converts the byte array to a hex string
   * and a base64 encoded string. The base64 encoded string is returned as the
   * client ID.
   *
   * @returns {string} - The generated random client ID string.
   */
  public static generateClientId(): string {
    
    const randomBytes = new Uint8Array(128);
    window.crypto.getRandomValues(randomBytes);
    const hexString: Array<string> = [];
    randomBytes.forEach((b) => hexString.push(b.toString(16).padStart(2, '0')));
    const clientId = btoa(String.fromCharCode(...randomBytes));

    return clientId;
  }

  /**
   * Checks if an array is empty.
   *
   * This function takes an array as input and returns true if the array is either null or undefined,
   * or if the array has a length of zero (meaning it contains no elements).
   *
   * @param {Array<any>} array - The array to check for emptiness.
   * @returns {boolean} - True if the array is empty, false otherwise.
   */
  public static isArrayEmpty(array?: Array<any>): boolean { /* eslint-disable-line */

    return (!array || array.length === 0);
  }

  /**
   * Converts a string to PascalCase (UpperCamelCase).
   * This function converts a string with spaces into PascalCase format by capitalizing the first letter of each word.
   * @param {string} str - The input string.
   * @returns {string} The converted string in PascalCase format.
   */
  public static toPascalCase(str: string): string {

    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }

  /**
   * Extracts the error message for specific API tasks from the API status.
   *
   * This method checks if the current API task matches any task in the provided task list.
   * If the task is found and is in an error state, the corresponding error message is returned.
   *
   * @param {Array<string>} taskList - A list of task identifiers to check.
   * @param {APIStatus | undefined} apiStatus - The API status object containing task information.
   * @returns {string | undefined} - The error message if a task from the list is in error state, otherwise undefined.
   */
  public static getApiError(taskList: Array<string>, apiStatus: APIStatus | undefined): string | undefined {

    return taskList.includes(apiStatus?.task ?? '') ? apiStatus?.error : undefined;
  }

  /**
   * Checks if a specific API task is currently loading.
   *
   * @param {string} task - The identifier of the API task.
   * @param {APIStatus | undefined} apiStatus - The API status object.
   * @returns {boolean} - True if the task is loading, false otherwise.
   */
  public static isApiLoading(task: string, apiStatus: APIStatus | undefined): boolean {

    return Boolean(apiStatus?.task === task && apiStatus?.isLoading);
  }

}