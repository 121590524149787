import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MessageIcon2 = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='64' height='65' viewBox='0 0 64 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M29.5832 51.1367L30.9974 49.7225L30.4116 49.1367H29.5832V51.1367ZM40.6689 62.2225L39.2547 63.6367L39.2547 63.6367L40.6689 62.2225ZM44.0831 51.1367V49.1367H42.0831V51.1367H44.0831ZM5 12.0117C5 8.69801 7.68629 6.01172 11 6.01172V2.01172C5.47715 2.01172 1 6.48887 1 12.0117H5ZM5 43.1367V12.0117H1V43.1367H5ZM11 49.1367C7.68629 49.1367 5 46.4504 5 43.1367H1C1 48.6596 5.47715 53.1367 11 53.1367V49.1367ZM29.5832 49.1367H11V53.1367H29.5832V49.1367ZM42.0831 60.8082L30.9974 49.7225L28.169 52.5509L39.2547 63.6367L42.0831 60.8082ZM42.0831 60.8082L42.0831 60.8082L39.2547 63.6367C41.7746 66.1565 46.0831 64.3719 46.0831 60.8082H42.0831ZM42.0831 51.1367V60.8082H46.0831V51.1367H42.0831ZM53 49.1367H44.0831V53.1367H53V49.1367ZM59 43.1367C59 46.4504 56.3137 49.1367 53 49.1367V53.1367C58.5228 53.1367 63 48.6596 63 43.1367H59ZM59 12.0117V43.1367H63V12.0117H59ZM53 6.01172C56.3137 6.01172 59 8.69801 59 12.0117H63C63 6.48887 58.5228 2.01172 53 2.01172V6.01172ZM11 6.01172H53V2.01172H11V6.01172Z' fill='currentColor' />
        <path d='M17.5 38.4492H26.5625H35.625' stroke='currentColor' strokeWidth='4' strokeLinecap='round' />
        <path d='M44.6875 38.4492H45.5938H46.5' stroke='currentColor' strokeWidth='4' strokeLinecap='round' />
        <path d='M46.5 27.5742L37.4375 27.5742L28.375 27.5742' stroke='currentColor' strokeWidth='4' strokeLinecap='round' />
        <path d='M19.3125 27.5742L18.4062 27.5742L17.5 27.5742' stroke='currentColor' strokeWidth='4' strokeLinecap='round' />
        <path d='M17.5 16.6992H26.5625H35.625' stroke='currentColor' strokeWidth='4' strokeLinecap='round' />
        <path d='M44.6875 16.6992H45.5938H46.5' stroke='currentColor' strokeWidth='4' strokeLinecap='round' />
      </svg>
    </SvgIcon>
  );
}

export default MessageIcon2;