import Dimens, { HTML_FONT_SIZE, SCALE, TYPOGRAPHY_FONT_SIZE } from './dimens';

import { Theme } from '@mui/material';
import { ThemeMode } from '../utils/constants';

/**
 * Utility class for various theme related helper functions.
 */
export default class ThemeUtil {

  /**
   * Converts a pixel value to rem units(unitless) based on the default font size.
   * 
   * @param {number} size The size in pixels to convert.
   * @returns {number} The converted size in rem without unit string.
   */
  public static pxToRemUnitless(size: number): number {

    return (size / HTML_FONT_SIZE * (TYPOGRAPHY_FONT_SIZE / 14));
  }

  /**
   * Converts a pixel value to rem units based on the default font size.
   * 
   * @param {number} size The size in pixels to convert.
   * @returns {string} The converted size in rem units.
   */
  public static pxToRem(size: number): string {

    return `${ThemeUtil.pxToRemUnitless(size)}rem`;
  }

  /**
   * Calculates a scaled font size based on an exponent.
   * 
   * This function uses the `HTML_FONT_SIZE` and `scale` factor to 
   * generate font sizes relative to the base size.
   * 
   * @param {number} exponent The exponent to apply to the scaling factor.
   * @returns {string} The calculated font size in rem.
   */
  public static scaledPixeltoRem(exponent: number): string {

    return ThemeUtil.pxToRem(HTML_FONT_SIZE * (SCALE ** exponent));
  }

  /**
   * Checks if the provided theme is in light mode.
   *
   * @param theme The theme object to check.
   * @returns True if the theme's mode is `ThemeMode.Light`, false otherwise.
   */
  public static isLightTheme(theme: Theme): boolean {

    return theme.palette.mode === ThemeMode.Light;
  }

}