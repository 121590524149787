const FR = {
  logout: 'Déconnexion',
  loading: 'Chargement...',
  error: 'Erreur',
  welcomeBack: 'Bienvenue',
  signInMsg: 'Connectez-vous avec les données que vous avez saisies lors de votre inscription.',
  email: 'Adresse e-mail',
  password: 'Mot de passe',
  forgotPwd: 'Mot de passe oublié ?',
  signIn: 'Connexion',
  emailRequired: 'L\'adresse e-mail est requise.',
  pwdRequired: 'Le mot de passe est requis.',
  emailInvalid: 'Adresse e-mail invalide.',
  verifyYou: 'Vérifiez que c\'est vous',
  verifyCodeMsg: 'Veuillez saisir le code à 6 chiffres que nous avons envoyé à ',
};

export default FR;