import Button, { ButtonProps } from '@mui/material/Button';

import React from 'react';
import { Typography } from '@mui/material';
import useStyles from './styles';

interface Props extends ButtonProps {
	/**
	 * If `true`, the component will show destructive app theme for button.
	 * @default false
	 */
	destructive?: boolean;
	/**
	 * If `true`, the component will lesser padding and text size.
	 * @default false
	 */
	small?: boolean;
}

/**
 * Custom Button component for displaying buttons with optional destructive styling.
 *
 * @props Inherited from ButtonProps and custom prop:
 *  - All props from MUI ButtonProps (e.g., title, color, variant, etc.)
 *  - destructive (boolean, optional): If `true`, the button displays a destructive action (e.g., delete). Defaults to false.
 * 
 * @returns {JSX.Element} - The rendered custom button component.
 */
const CustomButton: React.FC<Props> = (props: Props) => {

	const { destructive, small, title, ...rest } = props;
	const styles = useStyles(destructive, small, title);

	return (
		<Button
			{...rest}
			sx={{ ...styles.base, ...(rest.sx as any) }}  /* eslint-disable-line */
			variant='contained'
			disableElevation
		>
			<Typography variant={small ? 'footer' : 'p1'} sx={styles.label}>{title}</Typography>
		</Button>
	);
};

export default CustomButton;
