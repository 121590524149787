import { APP_DEFAULT_LANGUAGE, APP_STORAGE_IV, APP_STORAGE_SECRET, KEY_APP_LANGUAGE } from './constants';

import CryptoUtil from './crypto-util';

/**
 * Utility class for handling app-related operations.
 * This class uses AES encryption with a secret key and initialization vector (IV) to protect sensitive app information.
 */
export default class AppUtil {
	/**
	 * Internal storage instance used for storing login data.
	 * (Private to prevent direct modification)
	 */
	private static storage: Storage;

	/**
	 * Retrieves the storage instance (either `localStorage` or a custom implementation).
	 * This method uses lazy initialization to improve performance.
	 * @private
	 */
	private static getStorage(): Storage {
		if (!this.storage) {
			this.storage = localStorage;
		}

		return this.storage;
	}

	/**
	 * Save an item in storage after encrypting its value.
	 * @param key The key to store the data under.
	 * @param value The value to store.
	 */
	private static setItem(key: string, value: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, APP_STORAGE_SECRET, APP_STORAGE_IV);
		const encryptedValue = CryptoUtil.encryptAES(value, APP_STORAGE_SECRET, APP_STORAGE_IV);
		this.getStorage().setItem(encryptedKey, encryptedValue);
	}

	/**
	 * Retrieves an item from storage after decrypting its value.
	 * @param key The key to retrieve the data under.
	 * @returns The decrypted value from storage, or null if not found.
	 */
	private static getItem(key: string): string | null {
		const encryptedKey = CryptoUtil.encryptAES(key, APP_STORAGE_SECRET, APP_STORAGE_IV);
		const encryptedValue = this.getStorage().getItem(encryptedKey);
		const decryptedValue = encryptedValue ? CryptoUtil.decryptAES(encryptedValue, APP_STORAGE_SECRET, APP_STORAGE_IV) : null;

		return decryptedValue;
	}

	/**
	 * Removes an item from storage.
	 * @param key The key of the item to remove.
	 */
	private static removeItem(key: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, APP_STORAGE_SECRET, APP_STORAGE_IV);
		this.getStorage().removeItem(encryptedKey);
	}

	/**
	 * Clears all app details from storage.
	 */
	public static clearAll(): void {
		this.removeItem(KEY_APP_LANGUAGE);
	}

	/**
   * Saves the user's preferred language code in storage under the key `KEY_APP_LANGUAGE`.
   * 
   * This function likely uses the storage mechanism's `setItem` method to store the provided
   * `language` code under the key `KEY_APP_LANGUAGE`. This allows the application to persist
   * the user's language preference across sessions.
   *
   * @param {string} language - The language code to be saved as the user's preference.
   */
	public static saveAppLanguage(language: string) {
		this.setItem(KEY_APP_LANGUAGE, language);
	}

	/**
   * Retrieves the user's stored language preference from storage.
   * 
   * This function likely uses the storage mechanism's `getItem` method to retrieve the language
   * code stored under the key `KEY_APP_LANGUAGE`. If no language is found, it returns the default
   * language (`APP_DEFAULT_LANGUAGE`).
   *
   * @returns {string} - The user's stored language preference or the default language.
   */
	public static getAppLanguage(): string {
		const language: string = this.getItem(KEY_APP_LANGUAGE) || APP_DEFAULT_LANGUAGE;

		return language;
	}

}
