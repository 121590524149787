/**
 * Color palette and other application colors.
 * 
 * This object defines a set of color palettes and other colors used throughout the application.
 * Each color palette uses a Material Design naming convention with shades from 50 (lightest) to 900 (darkest).
 */
const Colors = {
  //Palette colors
  palette: {
    primary: {
      50: '#EBF6FE',
      100: '#D8EEFC',
      200: '#B1DDFC',
      300: '#89CBFA',  // Light Color (theme.palette.primary.light)
      400: '#62BAF9',
      500: '#3BA9F7',  // Main color (theme.palette.primary.main)
      600: '#0A8EEB',
      700: '#076AB0',  // Dark Color (theme.palette.primary.dark)
      800: '#054776',
      900: '#022338'
    },
    secondary: {
      50: '#F3F3F3',
      100: '#ECECEC',
      200: '#D9D9D9',
      300: '#C6C6C6',
      400: '#B3B3B3',
      500: '#A0A0A0',
      600: '#808080',
      700: '#606060',
      800: '#404040',
      900: '#202020'
    },
    success: {
      50: '#EBFEE4',
      100: '#D7FCCA',
      200: '#AFFA94',
      300: '#86F75F',
      400: '#5EF42A',
      500: '#68CC58',
      600: '#34AF09',
      700: '#278307',
      800: '#1A5804',
      900: '#0D2C02'
    },
    warning: {
      50: '#FEF5E7',
      100: '#FDEBCF',
      200: '#FCD7A0',
      300: '#FAC270',
      400: '#F9AE41',
      500: '#F79A11',
      600: '#CC7C07',
      700: '#995D05',
      800: '#663E03',
      900: '#331F02'
    },
    error: {
      50: '#FDE7E7',
      100: '#FCCECE',
      200: '#F89D9D',
      300: '#F56C6C',
      400: '#F23C3C',
      500: '#E91010',
      600: '#BA0D0D',
      700: '#8C0A0A',
      800: '#5D0606',
      900: '#2F0303'
    }
  },
  darkModeShades: {
    50: '#3E454A',
    100: '#3C4248',
    200: '#394046',
    300: '#353C42',
    400: '#323940',
    500: '#2E353B',
    600: '#2B3339',
    700: '#293137',
    800: '#252C33',
    900: '#192128'
  },
  transparent: 'rgba(0, 0, 0, 0)',
  white: '#FFFFFF',
  shadow: 'rgba(0, 0, 0, 0.08)'
}

export default Colors;
