import AppUtil from '../../utils/app-util';
import CustomSelect from '../CustomSelect';
import React from 'react';
import WorldIcon from '../CustomIcons/WorldIcon';
import { languageList } from '../../utils/constants';
import useStyles from './styles';

/**
 * LanguageSelector component for switching the application language.
 * 
 * This component allows users to choose their preferred language for the application interface.
 * It retrieves the current language, provides a dropdown menu for selection,
 * and saves the chosen language, triggering a page reload for the changes to take effect.
 *
 * @returns {JSX.Element} - The rendered language selector component.
 */
const LanguageSelector: React.FC = () => {

  const styles = useStyles();
  const languagePreference = AppUtil.getAppLanguage();

  /**
   * Handles the user changing the language selection in the dropdown.
   * 
   * This function is called when the user selects a new language from the dropdown menu.
   * It saves the selected language using `AppUtil.saveAppLanguage` and reloads the page
   * to trigger re-rendering of the interface with the new language.
   * 
   * @param {string} value - The selected language code from the dropdown.
   */
  const handleChange = (value: string) => {
    AppUtil.saveAppLanguage(value);
    location.reload();
  }

  return (
    <CustomSelect
      leftIcon={<WorldIcon sx={styles.icon} />}
      value={languagePreference}
      onChange={(e) => handleChange(e.target.value as string)}
      menu={languageList}
    />
  );
};

export default LanguageSelector;
