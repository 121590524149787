import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5642_6200)'>
          <path d='M1.5 8.5L15.5 8.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
          <path d='M8.5 1.5V15.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_5642_6200'>
            <rect width='16' height='16' fill='white' transform='translate(0.5 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default AddIcon;