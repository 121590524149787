import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SettingsIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M15.0222 4.34133C15.7796 1.21956 20.2204 1.21956 20.9778 4.34133C21.0914 4.81031 21.3142 5.24583 21.6279 5.61244C21.9417 5.97906 22.3376 6.26641 22.7834 6.4511C23.2292 6.6358 23.7123 6.71262 24.1934 6.67532C24.6745 6.63802 25.14 6.48765 25.552 6.23644C28.2951 4.56533 31.4364 7.70489 29.7653 10.4498C29.5145 10.8616 29.3644 11.3268 29.3271 11.8076C29.2899 12.2884 29.3667 12.7711 29.5512 13.2167C29.7356 13.6622 30.0226 14.0579 30.3888 14.3716C30.755 14.6854 31.1901 14.9083 31.6587 15.0222C34.7804 15.7796 34.7804 20.2204 31.6587 20.9778C31.1897 21.0914 30.7542 21.3142 30.3876 21.6279C30.0209 21.9417 29.7336 22.3376 29.5489 22.7834C29.3642 23.2292 29.2874 23.7123 29.3247 24.1934C29.362 24.6745 29.5124 25.14 29.7636 25.552C31.4347 28.2951 28.2951 31.4364 25.5502 29.7653C25.1384 29.5145 24.6732 29.3644 24.1924 29.3271C23.7116 29.2899 23.2289 29.3667 22.7833 29.5512C22.3378 29.7356 21.9421 30.0226 21.6284 30.3888C21.3146 30.755 21.0917 31.1901 20.9778 31.6587C20.2204 34.7804 15.7796 34.7804 15.0222 31.6587C14.9086 31.1897 14.6858 30.7542 14.3721 30.3876C14.0583 30.0209 13.6624 29.7336 13.2166 29.5489C12.7708 29.3642 12.2877 29.2874 11.8066 29.3247C11.3255 29.362 10.86 29.5124 10.448 29.7636C7.70489 31.4347 4.56356 28.2951 6.23467 25.5502C6.48551 25.1384 6.63565 24.6732 6.67286 24.1924C6.71007 23.7116 6.63332 23.2289 6.44884 22.7833C6.26436 22.3378 5.97735 21.9421 5.61115 21.6284C5.24495 21.3146 4.80989 21.0917 4.34133 20.9778C1.21956 20.2204 1.21956 15.7796 4.34133 15.0222C4.81031 14.9086 5.24583 14.6858 5.61244 14.3721C5.97906 14.0583 6.26641 13.6624 6.4511 13.2166C6.6358 12.7708 6.71262 12.2877 6.67532 11.8066C6.63802 11.3255 6.48765 10.86 6.23644 10.448C4.56533 7.70489 7.70489 4.56356 10.4498 6.23467C12.2276 7.31556 14.5316 6.35911 15.0222 4.34133Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.666 17.9993C12.666 19.4138 13.2279 20.7704 14.2281 21.7706C15.2283 22.7708 16.5849 23.3327 17.9993 23.3327C19.4138 23.3327 20.7704 22.7708 21.7706 21.7706C22.7708 20.7704 23.3327 19.4138 23.3327 17.9993C23.3327 16.5849 22.7708 15.2283 21.7706 14.2281C20.7704 13.2279 19.4138 12.666 17.9993 12.666C16.5849 12.666 15.2283 13.2279 14.2281 14.2281C13.2279 15.2283 12.666 16.5849 12.666 17.9993Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default SettingsIcon;