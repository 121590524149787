import {
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';

import CheckCircleOutlineIcon from '../CustomIcons/CheckCircleOutlineIcon';
import CheckFilledIcon from '../CustomIcons/CheckFilledIcon';
import React, { } from 'react';
import useStyles from './styles';

interface Props {
  label: string;
  isValid: boolean;
}

/**
 * Renders a single validation item for displaying real-time validation feedback.
 *
 * This component takes props for the validation label and its `isValid` status.
 * It utilizes a disabled checkbox with custom icons (`CheckCircleOutlineIcon` and `CheckFilledIcon`)
 * to visually represent the validation state. The label text style is also adjusted based
 * on `isValid` (active or inactive styles).
 *
 * This component is typically used within a loop to render a list of validation items
 * for a custom input component.
 *
 * @props {Props} - Component properties.
 *   - `label` (string): The text label for the validation item.
 *   - `isValid` (bool): Flag indicating if the validation criteria is met.
 *
 * @returns {JSX.Element} - The rendered validation item component.
 */
const ValidationItemView: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <FormControlLabel sx={styles.validationItem}
      control={
        <Checkbox
          icon={<CheckCircleOutlineIcon sx={styles.iconInactive} />}
          checkedIcon={<CheckFilledIcon sx={styles.iconActive} />}
          checked={props.isValid}
          sx={styles.checkbox}
          disabled
        />
      }
      label={
        <Typography
          variant='caption'
          sx={props.isValid ? styles.textActive : styles.textInactive}
        >
          {props.label}
        </Typography>
      }
    />
  );
};

export default ValidationItemView;
