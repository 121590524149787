import {
  API_FAILURE,
  ApiFailureAction,
  CREATE_ORG,
  FEATURE_LIST_SUCCESS,
  FETCH_FEATURE_LIST,
  FETCH_LICENCE_LIST,
  FETCH_ORG_DETAIL,
  FETCH_ORG_LIST,
  FeatureListFetchAction,
  FeatureListSuccessAction,
  INVITE_ADMIN,
  InviteAdminAction,
  LICENCE_LIST_SUCCESS,
  LicenceListFetchAction,
  LicenceListSuccessAction,
  ORG_LIST_SUCCESS,
  OrgCreateAction,
  OrgDetailFetchAction,
  OrgListFetchAction,
  OrgListSuccessAction,
  OrgUpdateAction,
  RESET_ERROR_STATE,
  ResetErrorStateAction,
  UPDATE_ORG
} from './action-types';

import Feature from '../../types/feature';
import Licence from '../../types/licence';
import Organization from '../../types/organization';

/**
 * Creates an action object to initiate fetching the organization list.
 * 
 * @returns {OrgListFetchAction} - The action object for fetching the organization list.
 */
export const fetchOrgList = (): OrgListFetchAction => ({
  type: FETCH_ORG_LIST
});

/**
 * Creates an action object to indicate a successful organization list fetch.
 * 
 * @param {Array<OrganizationDetail>} orgList - The fetched list of organizations.
 * @returns {OrgListSuccessAction} - The action object for successful organization list fetch.
 */
export const orgListSuccess = (orgList: Array<Organization>): OrgListSuccessAction => ({
  type: ORG_LIST_SUCCESS,
  payload: orgList
});

/**
 * Creates an action object to initiate fetching the licence list.
 * 
 * @returns {LicenceListFetchAction} - The action object for fetching the licence list.
 */
export const initLicenceListFetch = (): LicenceListFetchAction => ({
  type: FETCH_LICENCE_LIST
});

/**
 * Creates an action object to indicate a successful licence list fetch.
 * 
 * @param {Array<Licence>} licenceList - The fetched list of licences.
 * @returns {LicenceListSuccessAction} - The action object for successful licence list fetch.
 */
export const licenceListSuccess = (licenceList: Array<Licence>): LicenceListSuccessAction => ({
  type: LICENCE_LIST_SUCCESS,
  payload: licenceList
});

/**
 * Creates an action object to initiate fetching the feature list.
 * 
 * @returns {FeatureListFetchAction} - The action object for fetching the feature list.
 */
export const initFeatureListFetch = (): FeatureListFetchAction => ({
  type: FETCH_FEATURE_LIST
});

/**
 * Creates an action object to indicate a successful feature list fetch.
 * 
 * @param {Array<Feature>} featureList - The fetched list of features.
 * @returns {FeatureListSuccessAction} - The action object for successful feature list fetch.
 */
export const featureListSuccess = (featureList: Array<Feature>): FeatureListSuccessAction => ({
  type: FEATURE_LIST_SUCCESS,
  payload: featureList
});

/**
 * Creates an action object to initiate fetching the details of a specific organization.
 * 
 * @returns {OrgDetailFetchAction} - The action object for fetching organization details.
 */
export const initOrgDetailFetch = (): OrgDetailFetchAction => ({
  type: FETCH_ORG_DETAIL
});

/**
 * Creates an action object to initiate the creation of a new organization.
 * 
 * @returns {OrgCreateAction} - The action object for creating a new organization.
 */
export const initOrgCreate = (): OrgCreateAction => ({
  type: CREATE_ORG,
});

/**
 * Creates an action object to initiate updating an existing organization's details.
 * 
 * @returns {OrgUpdateAction} - The action object for updating an organization.
 */
export const initOrgUpdate = (): OrgUpdateAction => ({
  type: UPDATE_ORG,
});

/**
 * Creates an action object to initiate the process of inviting an admin to the organization.
 *
 * @returns {InviteAdminAction} - The action object for inviting an administrator.
 */
export const initAdminInvite = (): InviteAdminAction => ({
  type: INVITE_ADMIN,
});

/**
 * Creates an action object to indicate an API call failure with an error message.
 *
 * This function takes an error message as input and returns an action object
 * with the type `API_FAILURE` and the error message in the payload.
 *
 * @param {string} error - The error message encountered during the API call.
 * @returns {ApiFailureAction} - The action object for API call failure.
 */
export const apiFailure = (error: string): ApiFailureAction => ({
  type: API_FAILURE,
  payload: error
});

export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});