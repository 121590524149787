import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SaveIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5570_4168)'>
          <path d='M2.75 1.5H11.5L15 5V13.75C15 14.2141 14.8156 14.6592 14.4874 14.9874C14.1592 15.3156 13.7141 15.5 13.25 15.5H2.75C2.28587 15.5 1.84075 15.3156 1.51256 14.9874C1.18437 14.6592 1 14.2141 1 13.75V3.25C1 2.78587 1.18437 2.34075 1.51256 2.01256C1.84075 1.68437 2.28587 1.5 2.75 1.5Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M6.25 10.25C6.25 10.7141 6.43437 11.1592 6.76256 11.4874C7.09075 11.8156 7.53587 12 8 12C8.46413 12 8.90925 11.8156 9.23744 11.4874C9.56563 11.1592 9.75 10.7141 9.75 10.25C9.75 9.78587 9.56563 9.34075 9.23744 9.01256C8.90925 8.68437 8.46413 8.5 8 8.5C7.53587 8.5 7.09075 8.68437 6.76256 9.01256C6.43437 9.34075 6.25 9.78587 6.25 10.25Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M9.75 1.5V5H4.5V1.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_5570_4168'>
            <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default SaveIcon;