import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CustomSelect component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (hasBackground = false) => {

	const theme = useTheme();
	
	const styles = {
		wrapper: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      padding: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
			bgcolor: hasBackground ? theme.palette.background.default : 'transparent',
			borderRadius: Dimens.radius.md
    },
		leftIconWrapper: {
			pr: Dimens.spacing.sm,
			display: 'flex'
		},
		select: {
			flex: 1,
			'& .MuiOutlinedInput-input': {
				p: 0,
				display: 'flex',
				alignItems: 'center'
			},
			'& .MuiSelect-icon': {
				right: 0
			},
      '& fieldset': {
        border: 'none',
      }
    },
		caretIcon: {
			fontSize: Dimens.icon.sm
		},
    menuList: {
      p: 0
    },
    paper: {
			mt: Dimens.spacing.md,
      boxShadow: `0 ${Dimens.spacing.xxs} ${Dimens.spacing.xs} 0 ${Colors.shadow}`,
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md
    },
    menuItem: {
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      minWidth: Dimens.menuItemMinWidth,
			justifyContent: 'space-between',
			backgroundColor: theme.palette.background.paper,
			'&:hover': {
				backgroundColor: theme.palette.background.default,
			},
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.paper,
				'&:hover': {
					backgroundColor: theme.palette.background.default,
				}
			}
		},
		icon: {
			fontSize: Dimens.icon.sm
		},
		divider: {
			my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
		}
	};

	return styles;
};

export default useStyles;