import Colors from './colors';
import Dimens from './dimens';
import { PaletteMode } from '@mui/material';
import React from 'react';
import { colorModeManager } from './color-mode-manager';
import { createTheme } from '@mui/material/styles';

/**
 *  This file defines the application theme based on light or dark mode preferences.
 *  It leverages functions and variables fromDimens and Colors files.
 */

// Define base typography styles
const baseTypography = {
  fontFamily: 'Roboto',
  fontWeight: Dimens.fontWeight.medium
}

/**
 * getDesignTokens function generates theme configuration objects based on the provided mode (light or dark).
 *  - Breakpoints: Defines screen size thresholds for responsive adjustments.
 *  - Typography: Styles for various text elements (uses baseTypography and adjusts font sizes, letter spacing based on display size and mode).
 *  - Components: Customizes MUI components like Typography (sets default variant mappings for new variants).
 *  - Palette: Defines color configurations based on the mode (light or dark) using colors from the Colors file.
 *    - Primary, secondary, error colors are set based on mode with appropriate contrast text colors.
 *    - Text, background colors are set based on mode for readable text on background.
 */
const getDesignTokens = (mode: PaletteMode) => ({
  breakpoints: {
    values: {
      xs: 0, sm: 600, md: 840, lg: 1200, xl: 1440
    },
  },
  typography: {
    display1: {
      ...baseTypography,
      fontSize: Dimens.fontSize.display1,
      letterSpacing: '0.01em',
      lineHeight: '57px'
    },
    display2: {
      ...baseTypography,
      fontSize: Dimens.fontSize.display2,
      letterSpacing: '0.01em',
      lineHeight: '52px'
    },
    display3: {
      ...baseTypography,
      fontSize: Dimens.fontSize.display3,
      letterSpacing: '0.01em',
      lineHeight: '50px'
    },
    h1: {
      ...baseTypography,
      fontSize: Dimens.fontSize.h1,
      letterSpacing: '0.01em',
      lineHeight: '41px'
    },
    h2: {
      ...baseTypography,
      fontSize: Dimens.fontSize.h2,
      letterSpacing: '-0.02em',
      lineHeight: '36px'
    },
    h3: {
      ...baseTypography,
      fontSize: Dimens.fontSize.h3,
      letterSpacing: '-0.02em',
      lineHeight: '35px'
    },
    h4: {
      ...baseTypography,
      fontSize: Dimens.fontSize.h4,
      letterSpacing: '-0.02em',
      lineHeight: '28px'
    },
    h5: {
      ...baseTypography,
      fontSize: Dimens.fontSize.h5,
      letterSpacing: '0.01em',
      lineHeight: '25px'
    },
    h6: {
      ...baseTypography,
      fontSize: Dimens.fontSize.h6,
      letterSpacing: '-0.02em',
      lineHeight: '23px'
    },
    subheading: {
      ...baseTypography,
      fontWeight: Dimens.fontWeight.normal,
      fontSize: Dimens.fontSize.subHeading,
      lineHeight: '24px'
    },
    p1: {
      ...baseTypography,
      fontWeight: Dimens.fontWeight.normal,
      fontSize: Dimens.fontSize.p1,
      lineHeight: '23px'
    },
    p2: {
      ...baseTypography,
      fontWeight: Dimens.fontWeight.normal,
      fontSize: Dimens.fontSize.p2,
      lineHeight: '24px'
    },
    p3: {
      ...baseTypography,
      fontWeight: Dimens.fontWeight.normal,
      fontSize: Dimens.fontSize.p3,
      lineHeight: '26px'
    },
    caption: {
      ...baseTypography,
      fontWeight: Dimens.fontWeight.normal,
      fontSize: Dimens.fontSize.caption,
      lineHeight: '19px'
    },
    footer: {
      ...baseTypography,
      fontWeight:  Dimens.fontWeight.normal,
      fontSize: Dimens.fontSize.footer,
      letterSpacing: '0.02em',
      lineHeight: '17px'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          display1: 'h1',
          display2: 'h2',
          display3: 'h3',
          p1: 'p',
          p2: 'p',
          p3: 'p',
          subheading: 'p'
        },
      },
    }
  },
  palette: {
    mode,
    ...(mode === 'light' ? {
      ...Colors.palette,
      primary: {
        light: Colors.palette.primary[100],
        main: Colors.palette.primary[500],
        dark: Colors.palette.primary[600],
        contrastText: Colors.palette.secondary[50]
      },
      secondary: {
        light: Colors.palette.secondary[50],
        main: Colors.palette.secondary[50],
        dark: Colors.palette.secondary[100],
        contrastText: Colors.palette.secondary[800]
      },
      warning: {
        ...Colors.palette.warning,
        contrastText: Colors.palette.secondary[50]
      },
      success: {
        ...Colors.palette.success,
        contrastText: Colors.palette.secondary[50]
      },
      error: {
        light: Colors.palette.error[100],
        main: Colors.palette.error[500],
        dark: Colors.palette.error[600],
        contrastText: Colors.palette.secondary[100]
      },
      info: {
        ...Colors.palette.primary
      },
      text: {
        primary: Colors.palette.secondary[800],
        secondary: Colors.palette.secondary[500],
        disabled: Colors.palette.secondary[200]
      },
      divider: Colors.palette.secondary[50],
      background: {
        default: Colors.palette.secondary[50],
        paper: Colors.white
      },
      action: {
        disabled: Colors.palette.secondary[300]
      }
    } : {
      ...Colors.palette,
      primary: {
        light: Colors.palette.primary[800],
        main: Colors.palette.primary[500],
        dark: Colors.palette.primary[600],
        contrastText: Colors.darkModeShades[800]
      },
      secondary: {
        light: Colors.darkModeShades[700],
        main: Colors.darkModeShades[700],
        dark: Colors.darkModeShades[400],
        contrastText: Colors.palette.secondary[200]
      },
      warning: {
        main: Colors.palette.warning[600],
        contrastText: Colors.darkModeShades[800]
      },
      success: {
        main: Colors.palette.success[700],
        contrastText: Colors.darkModeShades[800]
      },
      error: {
        light: Colors.palette.error[700],
        main: Colors.palette.error[600],
        dark: Colors.palette.error[600],
        contrastText: Colors.palette.secondary[200]
      },
      info: {
        ...Colors.palette.primary
      },
      text: {
        primary: Colors.palette.secondary[100],
        secondary: Colors.palette.secondary[600],
        disabled: Colors.palette.secondary[700]
      },
      divider: Colors.darkModeShades[700],
      background: {
        default: Colors.darkModeShades[700],
        paper: Colors.darkModeShades[900]
      },
      action: {
        disabled: Colors.palette.secondary[600]
      }
    })
  }
});
declare module '@mui/material/styles' {
  interface TypographyVariants {
    display1: React.CSSProperties;
    display2: React.CSSProperties;
    display3: React.CSSProperties;
    p1: React.CSSProperties;
    p2: React.CSSProperties;
    p3: React.CSSProperties;
    subheading: React.CSSProperties;
    footer: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display1?: React.CSSProperties;
    display2?: React.CSSProperties;
    display3?: React.CSSProperties;
    p1?: React.CSSProperties;
    p2?: React.CSSProperties;
    p3?: React.CSSProperties;
    subheading: React.CSSProperties;
    footer?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    display3: true;
    p1: true;
    p2: true;
    p3: true;
    subheading: true;
    footer: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}

export default createTheme(getDesignTokens(colorModeManager.get()));

