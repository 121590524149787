const EN = {
	logout: 'Logout',
	loading: 'Loading...',
	error: 'Error',
	welcomeBack: 'Welcome back',
	signInMsg: 'Sign-in with the data that you entered during your registration',
	email: 'Email',
	password: 'Password',
	forgotPwd: 'Forgot Password?',
	signIn: 'Sign-in',
	emailRequired: 'Email is required.',
	pwdRequired: 'Password is required.',
	emailInvalid: 'Email is invalid.',
	verifyYou: 'Verify it’s you',
	verifyCodeMsg: 'Please enter the 6 digit code we sent to ',
	resetPwd: 'Reset Password',
	resetPwdMsg: 'No worries, enter your email and we’ll send you reset instructions.',
	newPwd: 'New Password',
	updatePwd: 'Update Password',
	pwdMinLength: 'At least 10 characters',
	pwdLowerCase: 'A lower case letter',
	pwdUpperCase: 'An upper case letter',
	pwdNumber: 'Contains a number',
	pwdSpecialChar: 'A special character',
	save: 'Save',
	badRequest: 'Invalid request.',
  unauthorized: 'Unauthorized access.',
  forbidden: 'Access forbidden.',
  notFound: 'The requested resource could not be found.',
  internalServerError: 'An unexpected error occurred on the server. Please try again later.',
  notImplemented: 'The server does not support the request.',
  serviceUnavailable: 'The server is currently unable to handle the request.',
	abortError: 'The request was aborted. Please try again.',
	typeError: 'A type error occurred. Please check your request data and try again.',
	syntaxError: 'A syntax error occurred. Please check your request data and try again.',
	networkError: 'A network error occurred. Please check your internet connection and try again.',
	unknownError: 'An error occurred:',
	defaultErrorMsg: 'Failed to process you request. Please try again later.',
	sessionExpiredMsg: 'The session has expired. Please try again',
	sessionTimeout: 'Session Timeout',
	sessionTimeoutMsg: 'For your security, we log you out automatically when you have not been active for a certain period of time.',
	logOut: 'Log out',
	continueSession: 'Continue Session',
	createOrg: 'Create Organization',
	details: 'Details',
	admins: 'Admins',
	features: 'Features',
	logs: 'Logs',
	backUp: 'Backup',
	expires: 'Expires',
	draft: 'Draft',
	active: 'Active',
	inActive: 'Inactive',
	cancel: 'Cancel',
	signOut: 'Sign-out',
	activate: 'Activate',
	activateOrg: 'Activate Organisation',
	activateOrgMsg: 'Are your sure you want to activate this organisation? Once active, admins will be sent invites and use any activated features.',
	deactivate: 'Deactivate',
	deactivateOrg: 'Deactivate Organisation',
	deactivateOrgMsg: 'Deactivating this organisation will stop any communication through the app. Data will be kept for 180 days after which it will be removed and cannot be recovered.',
	reactivate: 'Reactivate',
	reactivateOrg: 'Reactivate Organisation',
	orgName: 'Organisation name',
	name: 'Name',
	orgNameRequired: 'Organisation name is required.',
	licence: 'Licence',
	pleaseSelect: 'Please select',
	licenceRequired: 'Licence is required.',
	createReport: 'Create Report',
	report: 'Report',
	sptEmailRequired: 'Support email is required.',
	supportEmail: 'Support email',
	emailAddress: 'Email address',
	description: 'Description',
	optional: '(Optional)',
	add: 'Add',
	status: 'Status',
	search: 'Search',
	view: 'View',
	expiryDate: 'Expiry date',
  firstName: 'First name',
  lastName: 'Last name',
  addAdmin: 'Add admin',
  invite: 'Invite',
  editDetails: 'Edit details',
	charCountExceed:'{{field}} cannot be more than {{count}} characters.',
	fieldRequired: '{{field}} is required.',
  fieldInvalid: '{{field}} is invalid.',
	system: 'System',
	light: 'Light mode',
	dark: 'Dark mode',
	appearance: 'Appearance',
	upToMaxSize: "Up to {{maxSize}}"
};

export default EN;
