import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

import Dimens from '../../../../theme/dimens';
import MenuData from '../../../../types/ui/menu-data';
import React, { } from 'react';
import SearchBar from '../../../../components/SearchBar';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining props for OrgListSearchFilterHeader component.
 * 
 * @interface Props
 * @property {Array<MenuData>} orgStatusFilter - Array of MenuData objects representing organization status filters.
 * @property {function(string): void} onSearchChange - Callback function to handle search term changes.
 * @property {function(Array<MenuData>): void} onOrgStatusFilterChange - Callback function to handle organization status filter changes.
 */
interface Props {
  orgStatusFilter: Array<MenuData>;
  onSearchChange: (searchKey: string) => void;
  onOrgStatusFilterChange: (filter: Array<MenuData>) => void;
}

/**
 * Renders the search bar and organization status filter section for the organization list screen.
 * 
 * @param {Props} props - Component props.
 * @returns {JSX.Element} The OrgListSearchFilterHeader component.
 */
const OrgListSearchFilterHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  /**
   * Handles changes to individual organization status filter checkboxes.
   * Updates the filtered status based on the checked state.
   * 
   * @param {MenuData} option - MenuData object representing the selected filter option.
   * @param {boolean} checked - Checked state of the checkbox for the option.
   */
  const handleOrgStatusFilterChange = (option: MenuData, checked: boolean) => {
    const updatedMenu = props.orgStatusFilter.map((menu: MenuData) => {
      return menu.id === option.id ? { ...menu, isChecked: checked } : menu;
    });
    props.onOrgStatusFilterChange(updatedMenu);
  }

  return (
    <Box sx={styles.searchFilterLayout}>
      <SearchBar handleChange={props.onSearchChange} placeholder={t('search')} />
      <Box sx={styles.filterWrapper}>
        <Typography variant='p1' fontWeight={Dimens.fontWeight.semiBold}>{`${t('view')}:`}</Typography>
        <FormGroup sx={styles.formGroup}>
          {props.orgStatusFilter.map((option: MenuData, index: number) => {
            return (
              <FormControlLabel
                key={`checkbox-${index}`}
                label={<Typography variant='p1'>{option.value}</Typography>}
                control={
                  <Checkbox
                    checked={option.isChecked}
                    onChange={(e, checked) => handleOrgStatusFilterChange(option, checked)}
                  />
                }
              />)
          })}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default OrgListSearchFilterHeader;