import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the OrgListSearchFilterHeader component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xxs, base, lg, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    searchFilterLayout: {
      m: `${section} ${section} 0 ${section}`,
      [theme.breakpoints.down('sm')]: {
        m: `${lg} ${lg} 0 ${lg}`
      }
    },
    filterWrapper: {
      mt: base,
      mx: xxs,
      flexDirection: 'row',
      display: 'flex'
    },
    formGroup: {
			flexDirection: 'row',
      pl: lg,
			'.MuiFormControlLabel-root': {
				m: 0,
				pr: lg,
				'span': {
					p: 0,
					pr: xxs,
					'svg': {
						...commonStyles.smIcon
					}
				}
			}
		}
  };

  return styles;
};

export default useStyles;