import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EyeOffIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7.56125 7.07993C7.31705 7.32421 7.1799 7.65549 7.17996 8.0009C7.18002 8.34631 7.31729 8.67754 7.56158 8.92174C7.80586 9.16594 8.13714 9.30309 8.48255 9.30303C8.82796 9.30297 9.15919 9.1657 9.40339 8.92141' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12.1234 11.6346C11.0324 12.3172 9.76959 12.6752 8.48264 12.6667C5.68264 12.6667 3.3493 11.1112 1.48264 8.00006C2.47197 6.35118 3.59197 5.1394 4.84264 4.36473M7.06708 3.4734C7.533 3.37908 8.00727 3.33217 8.48264 3.3334C11.2826 3.3334 13.616 4.88895 15.4826 8.00006C14.9646 8.8634 14.4101 9.60773 13.8197 10.2323' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1.48264 1L15.4826 15' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default EyeOffIcon;