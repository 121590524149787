import { AdminStatus, OrgStatus } from '../../../../utils/constants';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';

import APIConfig from '../../../../service/api-config';
import AddAdminDialog from './AddAdminDialog';
import AddIcon from '../../../../components/CustomIcons/AddIcon';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import CustomButton from '../../../../components/CustomButton';
import InviteIcon from '../../../../components/CustomIcons/InviteIcon';
import Organization from '../../../../types/organization';
import OrganizationAdmin from '../../../../types/organization-admin';
import ReloadIcon from '../../../../components/CustomIcons/ReloadIcon';
import SaveIcon from '../../../../components/CustomIcons/SaveIcon';
import SearchBar from '../../../../components/SearchBar';
import Stack from '@mui/material/Stack';
import { useOrganizationApi } from '../../../../data/organization/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining props for AdminsTab component.
 * 
 * @interface Props
 * @property {Organization} organization - The organization object for which admins are displayed.
 */
interface Props {
	organization: Organization;
	handleAdminListChange: (adminList: Array<OrganizationAdmin>) => void;
}

/**
 * Renders the Admins tab for the organization details screen, displaying a list of organization admins.
 * 
 * @param {Props} props - Component props.
 * @returns {JSX.Element} The AdminsTab component.
 */
const AdminsTab: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const [ adminList, setAdminList ] = useState<Array<OrganizationAdmin>>([]);
	const [ searchKey, setSearchKey ] = useState<string>('');
	const [ openAdminDialog, setOpenAdminDialog ] = useState<boolean>(false);
	const orgApi = useOrganizationApi();

	/**
	 * Fetches the admin list from the organization object on component mount or when the organization prop changes.
	 */
	useEffect(() => {
		if (props.organization && props.organization.admins) {
			setAdminList(props.organization.admins);
		}
	}, [ props.organization ]);

	/**
	 * Filters the admin list based on the search keyword provided by the user.
	 * The search is case-insensitive and checks both the admin's name and login ID.
	 * 
	 * @returns {OrganizationAdmin[]} An array of OrganizationAdmin objects that match the search criteria.
	 * This array includes only those admins whose name or loginId contains the search key.
	 */
	const filterAdminList = () => {
		return (adminList.filter((admin) =>
			admin.name.toLowerCase().includes(searchKey.toLowerCase())
			|| admin.loginId.toLowerCase().includes(searchKey.toLowerCase())
		));
	};

	/**
	 * Checks if the organization's status is 'Active'.
	 * 
	 * @returns {boolean} True if the organization status is active, false otherwise.
	 */
	const isActiveStatus = (): boolean => {
		return props.organization.details.status === OrgStatus.Active;
	};

	/**
	 * Updates the state of the admin list with the new admin list provided.
	 * Closes the admin dialog once the list has been updated.
	 * 
	 * @param {Array<OrganizationAdmin>} adminList - The updated list of organization admins to set in state.
	 */
	const handleAdminListUpdate = (newAdminList: Array<OrganizationAdmin>) => {
		const uniqueAdminsMap = new Map();
		newAdminList.forEach(admin => { uniqueAdminsMap.set(admin.loginId, admin); });
		const updatedAdminList = Array.from(uniqueAdminsMap.values());
		setAdminList(updatedAdminList);
		setOpenAdminDialog(false);
		orgApi.resetError();
	};

	/**
	 * Handles the action of deleting an organization admin.
	 * 
	 * @param {OrganizationAdmin} admin - The admin object to be deleted.
	 */
	const handleDeleteAction = (admin: OrganizationAdmin) => {
		//TODO:
	};

	/**
	 * Handles the action of resending an invitation to an organization admin.
	 * 
	 * @param {OrganizationAdmin} admin - The admin object for whom the invite 
	 * is to be resent.
	 */
	const handleResendInvite = (admin: OrganizationAdmin) => {
		// Resend invite
		if (admin._links && admin._links?.invite) {
			const url = admin._links.invite.href;
			const inviteEndpoint = url.includes(APIConfig.relPath) ? url.split(APIConfig.relPath)[ 1 ] : '';
			orgApi.InviteOrganizationAdmin(inviteEndpoint);
		}
	};

	const filteredAdminList: OrganizationAdmin[] = filterAdminList();

	return (
		<Grid sx={styles.wrapper}>
			<Grid sx={styles.innerWrapper}>
				<Stack sx={styles.searchHeader}>
					<Stack sx={styles.searchBarWrapper}>
						<SearchBar handleChange={setSearchKey} placeholder={t('search')} />
					</Stack>
					<CustomButton
						onClick={() => setOpenAdminDialog(true)}
						title={isActiveStatus() ? t('invite') : t('add')}
						color='primary'
						endIcon={isActiveStatus() ? <InviteIcon sx={styles.icon} /> : <AddIcon sx={styles.icon} />}
					/>
				</Stack>
				<TableContainer sx={styles.tableContainer}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								{(props.organization.details.status === OrgStatus.Active) &&
									<TableCell>{t('status')}</TableCell>
								}
								<TableCell>{t('name')}</TableCell>
								<TableCell>{t('email')}</TableCell>
								<TableCell>{ }</TableCell>
								<TableCell>{ }</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredAdminList.map((admin, index) => (
								<TableRow key={`org-admin-${index}`}>
									{(props.organization.details.status === OrgStatus.Active) &&
										<TableCell style={styles.statusCell(admin.status ?? 'unknown') as React.CSSProperties}>
											<Box sx={styles.statusDot(admin.status ?? 'unknown')} />
											{admin.status?.toLocaleLowerCase()}
										</TableCell>
									}
									<TableCell>{admin.name}</TableCell>
									<TableCell>{admin.loginId}</TableCell>
									<TableCell onClick={(event) => {
										event.stopPropagation();
										handleResendInvite(admin);
									}}
									>{(props.organization.details.status === OrgStatus.Active && admin.status === AdminStatus.Pending) && <ReloadIcon sx={styles.icon} />}</TableCell>
									<TableCell onClick={(event) => {
										event.stopPropagation();
										handleDeleteAction(admin);
									}}>
										{admin.status === AdminStatus.Pending && <BinIcon sx={styles.binIcon} />}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{!props.organization.details.id &&
				<Box sx={styles.footer}>					
						<CustomButton type='submit' title={t('save')} color='primary' endIcon={<SaveIcon sx={styles.icon} />} onClick={() => props.handleAdminListChange(adminList)} />					
				</Box>
				}
			</Grid>
			<AddAdminDialog
				open={openAdminDialog}
				onClose={() => setOpenAdminDialog(false)}
				organization={props.organization}
				adminList ={adminList}
				onAdminSave={handleAdminListUpdate} />
		</Grid>
	);
};

export default AdminsTab;

