import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AdminIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='34' height='36' viewBox='0 0 34 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M17.0007 2C17.2818 2 17.5629 2.06284 17.8195 2.18225L29.3267 7.20346C30.6712 7.7879 31.6734 9.15161 31.6673 10.7981C31.6367 17.0322 29.1434 28.4383 18.614 33.6229C17.5934 34.1257 16.4079 34.1257 15.3873 33.6229C4.8579 28.4383 2.36457 17.0322 2.33401 10.7981C2.3279 9.15161 3.33012 7.7879 4.67456 7.20346L16.1879 2.18225C16.4384 2.06284 16.7195 2 17.0007 2Z' stroke='currentColor' strokeWidth='3' />
        <path d='M8.47656 27.7777C9.96461 25.8892 12.2946 24.6738 14.9144 24.6738H19.0855C21.9311 24.6738 24.4347 26.1077 25.8889 28.2805' stroke='currentColor' strokeWidth='3' />
        <path d='M17.0005 19.1517C18.3163 19.1517 19.5783 18.6381 20.5087 17.7239C21.4392 16.8098 21.9619 15.5699 21.9619 14.277C21.9619 12.9842 21.4392 11.7443 20.5087 10.8301C19.5783 9.91592 18.3163 9.40234 17.0005 9.40234C15.6846 9.40234 14.4227 9.91592 13.4922 10.8301C12.5618 11.7443 12.0391 12.9842 12.0391 14.277C12.0391 15.5699 12.5618 16.8098 13.4922 17.7239C14.4227 18.6381 15.6846 19.1517 17.0005 19.1517Z' stroke='currentColor' strokeWidth='3' />
      </svg>
    </SvgIcon>
  );
}

export default AdminIcon;