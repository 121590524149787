/**
 * useStyles custom hook generates MUI theme dependent styles for the FeaturesTab component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    innerWrapper: {
      flex: 1,
      overflowY: 'auto',
      p: 0
    },
    contentWrapper: {
      overflowY: 'auto',
      p: 0
    },
    content: {
      m: 0,  
      p: 0   
    },
  };

  return styles;
};

export default useStyles;