import { Route, Routes } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import MasterDetailLayout from '../../layouts/MasterDetailLayout';
import OrganizationDetailScreen from './OrganizationDetailScreen';
import OrganizationListScreen from './OrganizationListScreen';
import React from 'react';

/**
 * Organization Screen Component
 *
 * This component defines the routing structure for the organization management screens.
 * It utilizes a master-detail layout provided by `MasterDetailLayout`.
 *
 * Functionality:
 *  - Defines a single route path (`/*`) that matches any URL path within the organization section.
 *  - Renders the `MasterDetailLayout` component for this route.
 *      - Sets `MasterComponent` to `OrganizationListScreen`: This component renders the list of organizations in the master section.
 *      - Sets `DetailComponent` to `OrganizationDetailScreen`: This component renders the organization detail view when a specific organization is selected.
 *      - Sets `detailRelativePath` to `APP_NAV.ORG_DETAIL_REL_PATH`: This defines the relative path within the organization section that leads to the detail view (e.g., `/:organizationId`).
 *
 * @returns {JSX.Element} - The rendered React component tree for the organization screens using the master-detail layout.
 */
const OrganizationScreen: React.FC = () => {

  return (
    <Routes>
      <Route
        path='/*'
        element={
          <MasterDetailLayout
            MasterComponent={OrganizationListScreen}
            DetailComponent={OrganizationDetailScreen}
            detailRelativePath={APP_NAV.ORG_DETAIL_REL_PATH}
          />
        }
      />
    </Routes>
  );
};

export default OrganizationScreen;
