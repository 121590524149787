import AppUtil from '../utils/app-util';
import de from './translation/de';
import en from './translation/en';
import es from './translation/es';
import fr from './translation/fr';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  },
  de: {
    translation: de
  },
  es: {
    translation: es
  }
}

const locale = AppUtil.getAppLanguage();

i18n.use(initReactI18next).init({
  debug: false,
  compatibilityJSON: 'v3',
  resources,
  lng: locale.substring(0, 2),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;